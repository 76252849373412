<template>
    <div class="card mycard">
        <div class="growtHub__header">
                <img src="../assets/img/growthHub.png" style="height: 60px;" alt="">
                <div style="width: 60%; margin-left: 20px;" class="">
                    <h2 class="growtHub__header__title">Growth Hub</h2>
                    <span class="growtHub__header__desc">Add SKU's which your team can upsell or cross-sell
                    </span>
                </div>
        </div>
        <div style="display: flex; justify-content: end;">
            <AddButtonGlobal @button="handleNewData"/>
        </div>
        <div class="growtHub__body">
            <GlobalTable v-if="!loading && growthHubData.length>0" :columns="columns" :showAllTooltips="true" :tableData="growthHubData" @handleAction="handleAction($event)" />
                <div v-else-if="!loading && growthHubData.length==0" style="display: flex; justify-content: center; align-items: center;">
                    <img src="../icons/noData.svg" style="height: 12vw;" alt="">
                </div>
            <div v-else style="display: flex; align-items: center; justify-content: center;">
                <img src="../assets/loadingRipple.svg" style="height:7vw;" alt="">
            </div>
        </div>
        <div v-if="openAddNewModal" style="position: fixed; height: 100%; top: 0px; width: 100%; z-index: 1111; background-color: rgba(0, 0, 0, .3); left: 0px;">
            <div v-if="openAddNewModal" style="position: fixed; left: 25%; top: 25%; background-color: white; border: 0.2px solid grey; width: 50%; height: 50%; border-radius: 10px; z-index: 9999999999; padding: 5px 20px;
    box-shadow: rgba(0, 0, 0, 0.25) 0px 54px 55px, rgba(0, 0, 0, 0.12) 0px -12px 30px, rgba(0, 0, 0, 0.12) 0px 4px 6px, rgba(0, 0, 0, 0.17) 0px 12px 13px, rgba(0, 0, 0, 0.09) 0px -3px 5px;">
                <GrowthHubModal :prevData="currentGrowthHubData" @close="handleClose" @save="initData"/>
            </div>
        </div>
    </div>
</template>
<script>
import GlobalTable from "../layout/dashboard/GlobalTable.vue";
import Server from '../components/API/Server';
import AddButtonGlobal from "../layout/dashboard/AddButtonGlobal.vue";
import GrowthHubModal from "../components/GrowthHubModal.vue";
import moment from "moment";
export default{
    data(){
        return{
            columns:[
                {title:"Name",key:"productName",width:"16%"},
                {title:"Description",key:"Description",width:"20%"},
                {title:"Type",key:"Type",width:"10%"},
                {title:"Price Notes",key:"priceNotes",width:"20%"},
                {title:"Last Modified",key:"updatedAt",width:"10%"},
                {title:"Last Modified By",key:"updatedBy",width:"15%"},
                {title:"Action",key:"action",width:"10%"},
            ],
            loading: false,
            openAddNewModal: false,
            currentGrowthHubData: {},
            growthHubData:[]
        }
    },
    components: {
        GlobalTable,
        GrowthHubModal,
        AddButtonGlobal
    },
    async mounted(){
        this.initData();
    },
    methods: {
        handleNewData(){
            this.openAddNewModal = true;
        },
        handleClose(){
            this.openAddNewModal = false;
        },
        handleOpenEditData(data){
            console.log(data);
            this.openAddNewModal = true;
            this.currentGrowthHubData = data;
        },
        async handleDelete(data){
            console.log(data);
            let id = data.productId;
            let res = await Server.deleteProductCatalogue(id);
            console.log(res);
            if(res.code!=200){
                this.$toasted.error('Error deleting data');
                return;
            }
            this.$toasted.success('Data deleted successfully!');
            this.initData();
        },
        getDateFormat(date){
            return moment(date).format("DD MMM")
        },
        handleAction(action){
            switch (action[0]) {
                case 'openConfigure':
                    this.handleOpenEditData(action[1]);
                    break;
                case 'openDelete':
                    this.handleDelete(action[1]);
                    break;
            
                default:
                    break;
            }
        },
        async initData(){
            this.loading = true;
            console.log(this.growthHubData);
            this.growthHubData.length = 0;
            this.currentGrowthHubData = {};
            let resp = await Server.getProductCatalogue();
            for(let i=0;i<resp.data.length;i++){
                this.growthHubData.push({...resp.data[i], 'updatedAt': this.getDateFormat(resp.data[i].updatedAt), 'action': [{'img':'ConfigureIcon.png', 'action': 'openConfigure', 'hover': 'Configure'}, {'img':'Delete.png', 'action': 'openDelete', 'hover': 'Delete'}]})
            }
            this.loading = false;
            this.handleClose();
        }
    }

}
</script>

<style lang="scss" scoped>
.mycard{
    padding: 25px;
}
.growtHub{
    &__body{
        height: 69vh;
        margin-top: 0.7vw;
        overflow-y: scroll;
        overflow-x: hidden;
    }
 &__header {
        display: flex;
        align-items: center;

        &__img {
            height: 55px;
        }

        &__title {
            margin-bottom: 0px !important;
        }

        &__desc {
            margin-top: 4px;

        }
    }
}
</style>