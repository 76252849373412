<template>
    <div class="m">
        <div class="row">
            <div class="mainPanel">
                <FeedsConfigurationVue v-if="this.selectedVal == 0"></FeedsConfigurationVue>
                <SummaryConfig v-if="this.selectedVal == 1">
                </SummaryConfig>

            </div>
        </div>
    </div>
</template>

<script>
import FeedsConfigurationVue from './FeedsConfiguration.vue';
import SummaryConfig from "./SummaryConfig.vue"
export default {
    data() {
        return {
            selectedVal: 1
        }
    },
    setup() {


        return {}
    },
    methods: {
        handleSelectedTab(index) {
            this.selectedVal = index
        }
    },
    components: {
        FeedsConfigurationVue,
        SummaryConfig
    },
    async mounted() {

    }
    // side div of tabs.
    // <div class="tabs">
    //     <div @click="handleSelectedTab(0)" :class="selectedVal == 0 ? 'selected' : ''" class="tab">
    //         Feeds Configurator
    //     </div>
    //     <div @click="handleSelectedTab(1)" :class="selectedVal == 1 ? 'selected' : ''" class="tab">
    //         Summary Configurator
    //     </div>
    // </div>
}
</script>

<style lang="scss" scoped>

@import '../../assets/sass/black-dashboard/custom/themes';

.tab:hover {
    background-color: #e3e6fa7a;
}

.tabs {
    display: flex;
    flex-direction: column;
    width: 25%;
    cursor: pointer;

}

.row {
    margin-top: 10px;
}

.mainPanel {
    padding: 2px 3px;
    width: 100%;

    &__heading {
        font-weight: 700;
        font-size: 16px;
        margin-left: 20px;
        color:$sidebar-text-color;
    }
}

.tab {
    margin-left: 15px;
    margin-top: 40px;
    cursor: pointer;
    padding: 8px;
    text-align: center;

    &:nth-child(2n) {
        margin-top: 20px;
    }

    &.selected {
        margin-bottom: -2px;
        background-color: #075ff7;
        color: white;
        border-radius: 8px;
    }
}
</style>