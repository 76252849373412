<template>
    <div class="row">
      <div class="col-4">
        <card>
            <span>Total Data</span>
            <div style="justify-content:space-between;padding:4px 15px" class="row">
              <h2>{{totalData}}</h2>
              <div class="">
                <img  style="height:26px;margin-right:10px" v-bind:src="('https://s2.googleusercontent.com/s2/favicons?domain_url=https://' +'zendesk' + '.com&sz=48')" />
                <img  style="height:26px;margin-right:10px" v-bind:src="('https://s2.googleusercontent.com/s2/favicons?domain_url=https://' +'docs.google.com/spreadsheets/u/0/' + '.com&sz=48')" />
                <img  style="height:26px;margin-right:10px" v-bind:src="('https://s2.googleusercontent.com/s2/favicons?domain_url=https://' +'salesforce' + '.com&sz=48')" />
              </div>
            </div>
        </card>
        </div>
        <div class="col-4">
            <card>
            <span>Today's updated Data</span>
                <div style="justify-content:space-between;padding:4px 15px" class="row">
                <h2>{{totalUpdatedData}}</h2>
                <div class="data-cards">
                    <img  style="height:26px;margin-right:10px" v-bind:src="('https://s2.googleusercontent.com/s2/favicons?domain_url=https://' +'zendesk' + '.com&sz=48')" />
                    <img  style="height:26px;margin-right:10px" v-bind:src="('https://s2.googleusercontent.com/s2/favicons?domain_url=https://' +'docs.google.com/spreadsheets/u/0/' + '.com&sz=48')" />
                    <img  style="height:26px;margin-right:10px" v-bind:src="('https://s2.googleusercontent.com/s2/favicons?domain_url=https://' +'salesforce' + '.com&sz=48')" />
                
                </div>
                </div>
        </card>
        </div>
        <div class="col-4">
            <card>
            <span>Total Data added before today</span>
                <div style="justify-content:space-between;padding:4px 15px" class="row">
                <h2>{{beforeToday}}</h2>
                <div class="data-cards">
                    <img  style="height:26px;margin-right:10px" v-bind:src="('https://s2.googleusercontent.com/s2/favicons?domain_url=https://' +'zendesk' + '.com&sz=48')" />
                    <img  style="height:26px;margin-right:10px" v-bind:src="('https://s2.googleusercontent.com/s2/favicons?domain_url=https://' +'docs.google.com/spreadsheets/u/0/' + '.com&sz=48')" />
                    <img  style="height:26px;margin-right:10px" v-bind:src="('https://s2.googleusercontent.com/s2/favicons?domain_url=https://' +'salesforce' + '.com&sz=48')" />
                
                </div>
                </div>
        </card>
        </div>
      <div class="col-12">
        <card>
          <div class="table-responsive">
            <line-chart :styles="myStyles" :chart-data="datacollection" />
          </div>
        </card>
      </div>
    </div>
  </template>
  <script>
  import LineChart from "./LineChart.js";

  import Server from "../components/API/Server";
  import LocalUtils from "../components/Utils/LocalUtils";
  export default {
    name: "NewDashboard",
    components: {
      LineChart,
    },
    async mounted() {
      if (LocalUtils.getEmail()) {
        this.totalData=(await Server.queryAllData({}))["data"]
        var today=new Date()
        var dd = String(today.getDate()).padStart(2, '0');
        var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
        var yyyy = today.getFullYear();
         today = mm + '-' + dd + '-' + yyyy;
        this.totalUpdatedData=(await Server.queryAllData({"CreatedDate":{"$gte":today}}))["data"]
        this.beforeToday=this.totalData-this.totalUpdatedData
        this.isLoading = false;
      }
    },
    data() {
      return {
        totalData:"",
        totalUpdatedData:"",
        beforeToday:"",
        isLoading: false,
        myStyles: {
            height: '400px',
            width: '72vw',
            position: 'relative',
        },  
        datacollection: {
        labels: ["week 1", "week 2", "week 3", "week 4", "week 5", "week 6", "week 7", "week 8", "week 9", "week 10"],
        datasets: [
          {
            data: [86, 114, 106, 106, 107, 111, 133, 221, 283, 278],
            label: "Tickets",
            borderColor: "#3446fc",
            fill: false
          },
        ]
      }
      
        
  };
    }}
  </script>
  <style scoped lang="scss">
  .loading {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .data-cards{
    img{
      &:hover{
        scale:1.2;
        transition: 0.2s ease-in-out;
      }
    }
  }
  </style>
  