<template>
    <div id="app" class="configuration-container">
      <div class="configuration-sidebar">
        <!-- Sidebar with draggable list of sections -->
          <div 
            class="section-block" 
            v-for="(section, index) in configuration.sections" 
            :key="index" 
            @click="setActiveSection(section)">
            {{ section.title }}
          </div>
      </div>
  
      <div class="configuration-detail">
        <!-- Configuration detail section that changes based on the active section -->
        <div v-if="activeSection" class="section-configuration">
          <h2>{{ activeSection.title }}</h2>
          <input v-if="activeSection.type === 'text'" type="text" v-model="activeSection.content" />
          <!-- <select v-else-if="activeSection.type === 'select'" v-model="activeSection.selectedOption">
            <option v-for="option in activeSection.options" :value="option.value" :key="option.value">
              {{ option.text }}
            </option>
          </select> -->
          <div v-else class="grid">
            <draggable>
              <div v-for="card of activeSection.options" class="card">
                    {{ card.text }}
              </div>
            </draggable>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import draggable from 'vuedraggable';
  
  export default {
    components: {
      draggable,
    },
    data() {
      return {
        configuration: {
          "name": "",
          "description": "",
          "sections": [
            {
              "title": "WHAT",
              "content": "",
              "type": "text"
            },
            {
              "title": "WHERE",
              "content": "",
              "type": "text"
            },
            {
              "title": "WHEN",
              "options": [
                { "text": "DAILY ONCE", "value": "daily_once" },
                { "text": "DAILY 3 TIMES", "value": "daily_3_times" },
                { "text": "DAILY MULTIPLE TIMES", "value": "daily_multiple" },
                { "text": "WEEKLY ONCE", "value": "weekly_once" }
              ],
              "selectedOption": "",
              "type": "select"
            },
            {
              "title": "WHO",
              "content": "",
              "type": "text"
            }
          ],
          "types": {
            "tasklist": false,
            "dashboard": true,
            "accountSummary": false
          },
          "options": [
            { "name": "Renewal Dashboard", "selected": false },
            { "name": "Red Accounts Dashboard", "selected": false },
            { "name": "NPS Dashboard", "selected": false },
            { "name": "Executive Dashboard", "selected": false }
          ]
        },
        activeSection: null // This will hold the currently active section object
      };
    },
    methods: {
      setActiveSection(section) {
        this.activeSection = section;
      },
      saveConfiguration() {
        console.log(this.configuration);
        alert('Configuration saved!');
      }
    }
  };
  </script>
  
  <style lang="scss" scoped>
  .configuration-container {
    display: flex;
    height: 100vh;
  }
  
  .configuration-sidebar {
    flex: 0 0 250px;
    background-color: #f4f4f4;
    padding: 20px;
    overflow-y: auto;
  }
  
  .sections-list {
    padding: 0;
    margin: 0;
  }
  
  .section-block {
    padding: 10px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 4px;
    margin-bottom: 10px;
    cursor: pointer;
  }
  
  .section-block:hover {
    background-color: #e9ecef;
  }
  
  .configuration-detail {
    flex-grow: 1;
    padding: 20px;
  }
  
  .section-configuration {
    border: 1px solid #ddd;
    border-radius: 4px;
    padding: 20px;
    margin-top: 20px;
  }
  
  .section-configuration h2 {
    margin-top: 0;
  }
  .grid{
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-gap: 20px;
  }
  
  /* Additional styles... */
  </style>
  