<template >
    <div class="card mycard">
        <div class="allowedApps__header">
            <img src="../assets/successPad.png" style="height: 50px;" alt="">
                        <!-- <img class="playbook__header__img" src="../assets/playbook.png" /> -->
                        <div style="width: 60%;margin-left: 20px;" class="">
                            <h2 class="allowedApps__header__title">Success Pad</h2>
                            <span class="allowedApps__header__desc">Enable in-app 360 degree aggregated view widget for your enterprise data</span>
            </div>
        </div>
        <!-- <h2 id="success_pad_header" style="text-align: center;margin-bottom:4px;">Success Pad</h2> -->
        <!-- <span style="text-align:center">Success Pad will only be shown in the urls you have configured</span> -->
        <vue-confirm-dialog></vue-confirm-dialog>

        <!-- <span class="boldlabel">Enabled Web Apps</span> -->
        <!-- <span>Add web apps on which to show the AppEQ success pad for your team </span> -->
        <button @click="onAddModal()" class="addUrlButton">
            <i style="padding-bottom: 2px;" class="tim-icons icon-simple-add"></i>
            <span>Add</span>
        </button>
        <div>
            <table class="table tablesorter success_pad_table">
                <thead>
                    <slot name="columns">
                        <th v-for="column in columns" :key="column">
                            <span :id="'my_summary_'+column" >
                                {{ column }}
                            </span>
                        </th>
                    </slot>
                </thead>
                <img v-if="loading" class="loadingRipple" src="../assets/loadingRipple.svg">
                <tbody v-if="!loading">
                    <tr v-for="(app, index) of selectedApps">
                        <td v-for="column in columns" :key="column">
                            <div v-if="column==''"> 
                                <img style="object-fit: contain;" height="30px" width="30px" :src="app.logoURL" />
                            </div>
                            <div v-if="column=='Link'" class="dataRow_new_allowed_app"> 
                                <div style="width: 100%;">
                                    <div style="display: flex;justify-content: space-between;">
                                        <span style="margin-left: 10px;">{{ app.productName }}</span>
                                        

                                    </div>
                                    <div style="margin-left: 10px;">
                                        <a style="font-weight: 600;">Enabled Url Patterns : </a>
                                        <template v-for="link of app.defaultURL">
                                            <a> {{ link }} </a>
                                        </template>
                                    </div>
                                </div>    
                            </div>
                           
                            <div v-if="column=='Action'"> 
                                <div>
                                    <i v-tooltip="'Delete Url links'" @click="oncheckDel(index)" style="color: red;cursor: pointer;"
                                        class="tim-icons icon-trash-simple"></i>
                                    <i  v-tooltip="'Edit Url links'" style="margin-left: 20px;cursor: pointer" @click="onclickEdit(app,index)" class="tim-icons icon-pencil"></i>
                         
                                </div>
                            
                            </div>
                            <div v-if="column=='Enable/Disable'">
                                <div>
                                <label style="margin-left: 10px;
                                                display: block;
                                                margin-top: auto;
                                                margin-bottom: auto;" class="switch" v-tooltip="'New Allowed App Status'">
                        <input type="checkbox" v-model="app.enabled" @change="handleEnabledChange(app)">
                        <div class="slider round"></div>
                    
                    </label>

                </div>
            </div>
                        </td>

                    </tr>
                </tbody>
            </table>
        </div>
        <Modal :show="showAddModal" v-if="showAddModal">
            <i @click="showAddModal=false" class="tim-icons close-icon icon-simple-remove"></i>
            <div v-if="addImageUrl"
                style="width: 100%;display: flex;align-items: center;justify-content: center;margin-top: 10px;margin-bottom: 10px;">
                <img style="height: 40px;width: 40px;display: flex;align-items: center;justify-content: center;"
                    :src="addImageUrl" />

            </div>
            <div style="width: 100%;">
                <h4 id="success_pad_header">Add more links </h4>
                <div v-for="(url, index) of userUrl" style="display: flex;align-items: center;margin-top: 10px;">
                    <div class="domainbox">https://</div>
                    <input v-model="userUrl[index]" placeholder="Enter Url" class="inputStyle" style="width: 100%; margin-right: 10px;" />

                    <i v-if="userUrl.length - 1 == index" @click="onAddNewUrl"
                        style="margin-right: 10px;cursor: pointer;" class="tim-icons icon-simple-add"></i>
                    <i  @click="removeNewUrl(index)" style="color: red;cursor: pointer;" class="tim-icons icon-simple-remove"></i>
                </div>
                <br />
                <div style="    display: flex;
                align-items: center;
                position: absolute;
                justify-content: right;
                right: 10px;
                bottom: 10px;">
                    <span @click="showAddModal=false" style="margin-right: 12px;cursor: pointer;color: blue;">Close</span>
                    <button @click="onclickAdd()" style="margin-top: 0px;">Add</button>
                </div>

            </div>

        </Modal>
        <Modal modalContentClasses="myModal" :centered="true" :show="showDelModal" v-if="showDelModal">
            <i @click="showDelModal=false" class="tim-icons close-icon icon-simple-remove"></i>
            <div>
                Are you sure you want to delete ?
                <br />
                <div style="position: absolute;bottom:24px;right: 10px;">
                    <button @click="onclickDel">Yes</button>
                    <button  @click="showDelModal=false" style="margin-left: 10px;" class="no">No</button>
                </div>

            </div>


        </Modal>
    </div>
</template>
<script>
import Server from '../components/API/Server';
import Modal from "../components/Modal.vue"

export default {
    components: {
        Modal
    },
    data() {
        return {
            userUrl: [''],
            selectedApps: [],
            isEditing: false,
            showAddModal: false,
            editingIndex: -1,
            loading:false,
            alreadypresentdomains: [],
            addImageUrl: '',
            errorStr:'',
            deleteIndex: -1,
            showDelModal: false,
            columns:["","Link","Enable/Disable", "Action",]
        }
    },
    async mounted() {

        try{
            console.log("her")
            const data = await Server.getAllowedApps();

            console.log(data)
            console.log("her")

            this.selectedApps = data.data.data.apps
            this.selectedApps.map(data => {
                data.domain = []
                data.defaultURL.map(d => {
                    const domainName = (url) => url.match(/(?:http(?:s)?:\/\/)?(?:w{3}\.)?([^\.]+)/i)[1];

                    data.domain.push(domainName(d))
                })
            })
            console.log("her")

            this.selectedApps.map(dom => { this.alreadypresentdomains.push(...dom.domain) })
            this.selectedApps.map(dom => { this.alreadypresentdomains.push(...dom.domain) })
            this.selectedApps.sort((x,y)=>{
                return (x.enabled === y.enabled)? 0 : x.enabled? -1 : 1;
            })
            console.log(this.alreadypresentdomains)
        }catch(e){
            console.log(e);
        }
    },
    methods: {
        async handleEnabledChange(app) {
      if (app.enabled) {
        // Enabling, make API call directly
        await this.saveAllowedApps();
      } else {
        // Disabling, show confirm dialog
        this.showConfirmDialog(app);
      }
    },
    async saveAllowedApps() {
      await Server.saveAllowedApps(this.selectedApps);
    },
    async showConfirmDialog(app) {
      this.$confirm({
        message: 'Are you sure you want to disable SuccessPad on selected applications?',
        button: {
          no: 'No',
          yes: 'Yes',
        },
        callback: async (confirm) => {
          if (confirm) {
            await this.saveAllowedApps();
          } else {
            app.enabled = !app.enabled; // Revert the change
          }
        },
      });
    },
        onAddModal() {
            this.isEditing = false;
            this.userUrl=['']
            this.showAddModal = true;
        },
        oncheckDel(index) {
            this.deleteIndex = index;
            this.$confirm(
        {
          message: 'Are you sure you want to delete?',
          button: {
            no: 'No',
            yes: 'Yes'
          },
          /**
          * Callback Function
          * @param {Boolean} confirm
          */
          callback: confirm => {
            if (confirm) {
              // ... do something
              this.onclickDel(index)
            }else{

            }
          }
        }
      )
        },
        async onclickDel() {
            console.log(this.deleteIndex)
            this.selectedApps.splice(this.deleteIndex, 1); // 2nd parameter means remove one item only

            this.selectedApps.map((data, index) => {
                data['id'] = index
            })
            await Server.saveAllowedApps(this.selectedApps)
            this.showDelModal = false
        },
        onclickEdit(app,index) {
            this.isEditing = true;
            this.showAddModal = true;
            this.userUrl = []
            this.editingIndex = index
            const domainName = (url) => url.match(/(?:http(?:s)?:\/\/)?(?:w{3}\.)?([^\.]+)/i)[1];

            app.defaultURL.map(data => {
                this.userUrl.push(data)

                this.addImageUrl = 'https://s2.googleusercontent.com/s2/favicons?domain_url=https://' + domainName(data) + ".com"
            })
        },
        async onclickAdd() {
            try {
                if (this.isEditing) {
                    this.selectedApps[this.editingIndex].defaultURL = this.userUrl
                    await Server.saveAllowedApps(this.selectedApps)
                    this.$toasted.success("Successfully saved the configuration")
                    this.isEditing=false
                    this.showAddModal=false
                    return;
                }
                const domainName = (url) => url.match(/(?:http(?:s)?:\/\/)?(?:w{3}\.)?([^\.]+)/i)[1];
                var dom = ''
                var error=false;
                this.userUrl.map(data => {
                    const domain = domainName(data)
                    this.addImageUrl = 'https://s2.googleusercontent.com/s2/favicons?domain_url=https://' + domain + ".com"
                    dom = domain
                    console.log(this.alreadypresentdomains)
                    if (this.alreadypresentdomains.filter(d => d == domain).length > 0) {
                        this.$toasted.error("Domain already present please add the url at that location")
                        error=true;
                        return
                    }
                })
                if(error){
                    return
                }
                this.selectedApps.push({ id: this.selectedApps.length + 1, g2Category: '', defaultURL: this.userUrl, logoURL: this.addImageUrl, productName: dom })
                await Server.saveAllowedApps(this.selectedApps)
                this.showAddModal = false


            } catch (error) {
                this.$toasted.error(error)

                console.log(error)
            }
        },
        onAddNewUrl() {
            this.userUrl.push("")
        },
        removeNewUrl(index){

            if(this.userUrl && this.userUrl.length==1){
                return;
            }
            if (index > -1) { // only splice array when item is found
                this.userUrl.splice(index, 1); // 2nd parameter means remove one item only
            }

        }
    }
}
</script>
<style >
.vc-text{
    color: black !important;
}
</style>
<style lang="scss" scoped>
.mycard {
    padding: 20px;
}
.modal-body {
    padding: 30px !important;
}

.boldlabel {
    font-weight: 800;
    font-size: 16px;
}

button {
    width: 140px;
    height: 30px;
    border-radius: 5px;
    margin-top: 10px;

    span {
        margin-left: 10px;
    }
}

.footer {
    position: fixed;
    bottom: 0px;
    width: 100%;
    right: 0px;
    background: white;
    display: flex;
    align-items: center;
    justify-content: flex-end;
}
.domainbox {
  height: 40px;
  display: flex;
  align-items: center;
  background: rgb(244, 246, 250);
}

.no {
    background: white;
    border: 1px solid blue;
    color: black;
}

.inputStyle {
    width: 100%;
    outline: none;
    border: 1px solid #dce2ea;
    height: 40px;
}



.addUrlButton{
    width: 150px;
    height: 30px;
    border-radius: 5px;
    position: absolute;
    right: 10px;
    top: 15px;
}

.success_pad_table{
    margin-top: 20px;
    
    .dataRow_new_allowed_app{


        a{
            color: rgb(81, 81, 81);
            font-weight: 500;
            font-size: 12px;
        }
    }
}

.switch {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
}

.switch input {
    display: none;
}

.slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

.slider:before {
    position: absolute;
    content: "";
    height: 20px;
    width: 21px;
    left: 0px;
    bottom: 0px;
    background-color: blue;
    -webkit-transition: 0.4s;
    transition: 0.4s;
}

input:checked+.slider {
    background-color: lightblue;
}

input:focus+.slider {
    box-shadow: 0 0 1px #101010;
}

input:checked+.slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
}

.slider.round {
    border-radius: 34px;
}

.slider.round:before {
    border-radius: 50%;
}
.allowedApps{
    padding: 14px;

    &__body {
        width: 100%;
        margin-top: 20px;
        margin-left: 25px;
        margin-right: 30px;
    }

    &__header {
        display: flex;
        align-items: center;
        margin-top: 20px;
        margin-left: 25px;

        &__img {
            height: 55px;
        }

        &__title {
            margin-bottom: 0px !important;
        }

        &__desc {
            margin-top: 4px;

        }
    }
}

</style>