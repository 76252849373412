<template>
    <div class="object_configuration">

        <div class="header">
            <i @click="handleClose()" class="tim-icons close-icon icon-simple-remove"></i>
            <h2 style="margin-bottom: 0px;text-transform: capitalize; text-align: center; font-weight: 600;">{{creds.appeq_supported_application}} Integration</h2>
        </div>
        <hr>
        <div>
            <div style="margin: 10px; padding: 10px; border: 12px solid #f9f5f5;">
                <div>
                    <h3 style="margin-bottom: 7px; font-size: 20px;text-transform: capitalize;">{{creds.appeq_supported_application}} URL</h3>
                </div>
                <div>
                    <p style="color: black; font-weight: 500;">Below is the path of the {{creds.appeq_supported_application}} instance for your organization</p>
                </div>
                <div style="display: flex; align-items: center; justify-content: space-between; width: 40%;">
                    <div v-if="credSheetUrl">
                        <div v-if="!editClicked && !markItAsArray" @click="openUrl" style="color: blue; cursor: pointer;">{{ credSheetUrl }}</div>
                        <div v-else-if="!editClicked && markItAsArray">
                            <div @click="openUrl" v-for="val in credSheetUrl" style="color: blue; cursor: pointer;">
                                {{ val }}
                            </div>
                        </div>
                        <div v-else-if="editClicked && !markItAsArray">
                            <input type="text" style="outline: auto; width: 16rem;" v-model="credSheetUrl">
                            <img src="../assets/checkMark.png" @click="handleSaveNewCredSheetUrl" style="height: 24px; cursor: pointer; margin-left: 20px;" alt="">
                        </div>
                        <div v-else-if="editClicked && markItAsArray">
                            <div v-for="(val, idx) in credSheetUrl">
                                <input type="text" style="outline: auto; width: 16rem;" v-model="credSheetUrl[idx]">
                                <img src="../assets/checkMark.png" @click="handleSaveNewCredSheetUrl" style="height: 24px; cursor: pointer; margin-left: 20px;" alt="">
                            </div>
                        </div>
                        <div>
                            <img v-if="!editClicked" src="../assets/edit.svg" @click="editIntegration()" style="height: 24px; cursor: pointer; filter: invert(-20%) sepia(100%) saturate(4888%) hue-rotate(81deg) brightness(-18%) contrast(23%);" alt="">
                        </div>
                    </div>
                    <div v-else style="display: flex; justify-content: space-between; align-items: center; width: 85%;">
                        <div>Access token already connected.</div>
                        <div style="cursor: pointer; background: #6161ff; color: white; font-size: 11px; padding: 5px; border-radius: 5px;" @click="reconnect">Reconnect</div>
                    </div>
                </div>
            </div>
            <!-- <div style="margin: 10px; padding: 10px; border: 12px solid #f9f5f5;">
                <div>
                    <h3 style="margin-bottom: 7px; font-size: 20px;">Team Sync Credentials</h3>
                </div>
                <div>
                    <h4>Use the following user's  authentication credentials to sync with AppEQ</h4>
                </div>
                <div>
                    <p style="color: black;">Connect using admin account(read/write permission) for best results. We may have trouble syncing your records if your user does not have permission to see/edit all records.</p>
                </div>
                <div>
                    <Multiselect :options="['a','b','c']" />
                </div>
                <div>
                    <button style="width: 100px; height: 34px; border-radius: 9px; background: #6c6cff;">Save</button>
                </div>
            </div> -->
            <div style="margin: 10px; padding: 10px; border: 12px solid #f9f5f5;">
                <div>
                    <h3 style="margin-bottom: 7px; font-size: 20px;">Business Object Sync settings</h3>
                </div>
                <div>
                    <p style="color: black;">Use the options below to sync busiess objects between AppEQ and Salesforce.</p>
                </div>
                <div>
                    <div v-for="(cred, idx) of creds.root" style="margin: 5px; display: flex; justify-content: space-between; align-items: center; width: 50%;">
                        <span>
                            <span><img src="../assets/greenCheck.png" style="height: 20px;" alt=""></span>
                        <span style="margin-left: 10px;">{{ creds.business_object_name[idx] }}</span>
                        <span v-if="creds.appeq_supported_application=='jira' && cred['project_key']"> ({{ cred['project_key'] }} )</span>
                        </span>
                        <span style="margin-left: 8px;"><img src="../assets/deleteIconRed.webp" style="height: 20px; cursor: pointer;" @click="handleDeleteCurrentBobj(cred, idx)" alt=""></span>
                    </div>
                  
                    <div style="display: flex; align-items: center; justify-content: end;">
                        <button @click="handleOpenAddMore" style="width: 140px; height: 40px; border-radius: 12px;">Add More</button>
                    </div>
                </div>
            </div>
        </div>
        <Modal v-if="toOpen" @close="toOpen = false" class="modal-search" id="searchModal" :centered="true"
            :show-close="true" :show="toOpen">
            <div class="headers" slot="header">
                <div class="title">
                </div>
            </div>
            <AppsList :selectedProp="itemToOpen" :openFromNew="true" :imageUrl="itemToOpen" />
        </Modal>
        <Modal v-if="showDescPage" @close="showDescPage = false" class="modal-search" id="searchModal" :centered="true"
            :show-close="true" :show="showDescPage">
            <div class="headers" slot="header">
                <div class="title">
                </div>
            </div>
            <DescPage v-if="showDescPage" :imageUrl="creds" />
        </Modal>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect'
import AppsList from './AppsList.vue';
import Modal from '../components/Modal.vue';
import Server from '../components/API/Server';
import DescPage from '../components/DescPage.vue';
import LocalUtils from '../components/Utils/LocalUtils';
export default {
    props:{
        creds:{}
    },
    mounted() {
    console.log(this.creds);
    this.credData = this.creds;
        setTimeout(() => {
            document.querySelector(".object_configuration").style.right = "0px";
        }, 200);
        let url = this.creds.instance_url ?this.creds.instance_url:(this.creds.sheet_url?this.creds.sheet_url:this.creds.token);
        console.log(url,'ye hai url');
        if(url && Array.isArray(url)){
            this.markItAsArray = true;
            this.credSheetUrl = url;
        }else{
            this.credSheetUrl = url;
        }
        console.log(this.credSheetUrl);
    },
    data() {
        return {
            toOpen:false,
            editClicked: false,
            credSheetUrl: '',
            markItAsArray: false,
            showDescPage: false,
            credData: {}
        };
    },
    methods: {
        openUrl(){

        },
        handleClose() {
            this.$emit("close");
        },
        async handleDeleteCurrentBobj(cred, idx){
            console.log('cred is', cred, idx);
            console.log(this.creds);
            let resp = await Server.resetSource('delete',cred.business_object_name.length>0 ? cred.business_object_name[idx] : this.credData.business_object_name, cred.appeq_supported_application);
            console.log(resp);
            window.location.reload();
        },
        editIntegration(){
            // console.log('credData is',this.credData)
            // this.$emit('editToOpen', this.credData);
            // this.$emit('close');
            this.editClicked=true;
            console.log(this.editClicked)
            console.log(this.credSheetUrl, 'url')
        },
        async handleSaveNewCredSheetUrl(){
            let credsData = await Server.googleSheetCreds();
            console.log(credsData);
            console.log(this.credSheetUrl);
            // const bObj = localStorage.getItem("Business_obj_name");
            //  console.log(bObj.split(","))
             await Promise.all(credsData.data.map(async d=>{
               if(d.appeq_supported_application==='salesforce'){
                const res = await Server.saveSalesforceData({
               instance_url: this.credSheetUrl,
               "admin-email": LocalUtils.getEmail(),
               ...d
              });
              console.log(res);
               }
            })
            );
            this.editClicked=false;
        },
        async reconnect(){
            this.creds['productName'] = this.creds.appeq_supported_application;
            this.itemToOpen=this.creds
            this.toOpen=true
        },
        handleOpenAddMore(cred){
            this.itemToOpen=this.creds
            this.creds.productName=this.creds.appeq_supported_application.toString()
            this.toOpen=true
        }
    },
    components: { Multiselect,AppsList,Modal, DescPage }
}
</script>
<style lang="scss" scoped>
    .object_configuration{
        position: fixed;
        overflow: scroll;
    right: -60vw;
    transition: right 1s ease-in-out;
    width: 60vw;
    top: 0px;
    height: 100vh;
    background-color: white;
    z-index: 999999;
    border-radius: 20px 0px 0px 20px;
    padding: 13px 25px;
    box-shadow: 5px 5px 0 0 #525f7f;
    border: 1px solid #525f7f;
    }
    .header{
        margin-top: 20px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin-bottom: 0px;
    }
</style>