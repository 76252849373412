<template >
    <div class="card mycard">
        <h2 style="text-align: center;margin-top:10px;margin-bottom: 4px;">Custom Fields</h2>
        <span style="text-align: center;margin-bottom: 10px;">Create custom fields based on your formulas</span>
        <button style="width: 15%;height: 30px;margin-bottom: 20px;margin-left: 80%;" @click="openModal = true">
            <i class="tim-icons icon-simple-add"></i>
            Add</button>
        <table class="table tablesorter">
            <thead>
                <slot name="columns">
                    <th v-for="column in columns" :key="column">
                        <span :id="'user_' + column">
                            {{ column }}
                        </span>
                    </th>
                </slot>
            </thead>
            <tbody>
                <tr v-for="u, ind of allFields">
                    <td v-for="column in columns" :key="column">
                        <div v-if="column == 'Business Object'">
                            {{ u['business_object_name'] }}
                        </div>
                        <div v-if="column == 'Field name'">
                            {{ u['fieldName'] }}
                        </div>
                        <div v-if="column == 'Last Updated At'">
                            {{ getHumanDate(u['lastUpdatedAt']) + ' ago'}}
                        </div>
                        <div v-if="column == 'Updated By'">
                            {{ u['lastUpdatedBy'] }}
                        </div>
                        <div style="display: flex;align-items: center;" v-if="column == 'Actions'">
                            <img style="cursor: pointer;" v-tooltip.bottom-end="'Edit fields'" @click="handleEdit(u)"
                                class="summary_action_visibility" src="../assets/edit.svg" />
                            <span v-tooltip.bottom-end="'Delete fields'" @click="handleDelete(u)" style="color: red;cursor: pointer;"
                                class="material-symbols-outlined summary_action_visibility">
                                delete
                            </span>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
        <Modal class="functionClass" :show="openModal" v-if="openModal">
            <i @click="openModal = false" class="tim-icons close-icon icon-simple-remove"></i>

            <h3
                style="text-align: center;display: flex;align-items: center;justify-content: center;">Custom Field
            </h3>
            <br />
            <div style="display: flex;align-items: center;justify-content: space-between;">
                <div style="flex: 1;">

            <span>Name of field</span>
            <br />
            <input style="border: none;border-bottom: 1px solid #e8e8e8;margin-bottom: 10px;" v-model="funcName"
                placeholder="Enter name of the field" type="text" />
            </div>
            <div style="flex: 1;">
                <span>Business Object</span>
            <Multiselect @select="handleBusinessObjectSelectTemp(selectedBObject)" v-model="selectedBObject"
                :options="bObjectList" />
            </div>
          
            <br />
            </div>

            <span>Operations</span>

            <br />
            <textarea placeholder="Enter the formula" @input="handleChange" v-model="functionType"
                style="border: 1px solid #e8e8e8;margin-top: 10px;width: 100%;background-color: #efedf3;" />
            <div style="height: 200px;overflow: auto;width: 300px;background-color: white;">
                <ul style="list-style: none;padding-left: 0px;">
                    <li @click="addInp(sug)" v-for="sug in suggestions">
                        {{ sug }}
                    </li>
                </ul>

            </div>
            <button @click="handleSave"
                style="width: 30%;height: 40px;border-radius: 5px;margin-top: 20px;position: absolute;right: 20px;bottom: 10px;">Save</button>
            <!-- <autocomplete :suggestions="suggestions" @changeSugges="handleChangeSugg" >      </autocomplete> -->

        </Modal>
    </div>
</template>
<script>
import Multiselect from 'vue-multiselect';
import Server from '../components/API/Server';
import _ from "underscore.string";

import { operetors } from "./Functions"
import Modal from '../components/Modal.vue';
import LocalUtils from '../components/Utils/LocalUtils';
export default {
    components: { Multiselect, Modal },
    data() {
        return {
            bObjectList: [],
            selectedBObject: '',
            selectedFunc: '',
            openModal: false,
            functionType: '',
            tempKeys: [],
            columns: ["Business Object", "Field name", "Last Updated At", "Updated By","Actions"],
            suggestions: [],
            operetors: operetors,
            value: '',
            values: [],
            allFields: [],
            ops: [''],
            funcName: '',
            selection: '',
            functionRows: [{ selectedFunc: '', selectedKey: '', selectedOp: '', selectedValue: [] }]
        }
    },
    methods: {
        handleEdit(data){
            this.selectedBObject=data.business_object_name,
            this.funcName=data.fieldName
            this.functionType=data.fieldFunc
            this.openModal=true
        },
        getHumanDate: function (date) {
            var date = new Date((date))
            var seconds = Math.floor((new Date() - date) / 1000);

            var interval = Math.floor(seconds / 31536000);

            if (interval > 1) {
                return interval + " years";
            }
            interval = Math.floor(seconds / 2592000);
            if (interval > 1) {
                return interval + " months";
            }
            interval = Math.floor(seconds / 86400);
            if (interval > 1) {
                return interval + " days";
            }
            interval = Math.floor(seconds / 3600);
            if (interval >= 1) {
                return interval + " hours";
            }
            interval = Math.floor(seconds / 60);
            if (interval > 1) {
                return interval + " minutes";
            }
            return Math.floor(seconds) + " seconds";
        },
        addInp(v) {
            console.log(v)
            let expression = this.functionType
            expression = expression.replace(/\s/g, '');

            // Split the expression using the operators (+, -, *, /, etc.) as separators
            var values = expression.split(/([-+*/<>=!]+|&&|\|\||\(|\)|,|if|else|{|})/);
            values = values.filter(function(value) {
    return /^[a-zA-Z_$][a-zA-Z_$0-9]*$/.test(value);
  });
            let templength = values[values.length - 1].length
            values[values.length - 1] = v
            const val = _.splice(this.functionType, this.functionType.length - templength, templength, v)
            this.functionType = val;
            this.suggestions = []
        },
        async handleDelete(data){
            const vals = [];
            this.allFields.map(d => {
                if (d.business_object_name == data.business_object_name && d.fieldName!=data.fieldName) {
                    vals.push({ ...d })
                }
            })
            await Server.saveCustomFields(data.business_object_name, vals)
            this.$toasted.success("Successfully deleted the fields")
            this.handleInit()
        },
        handleChange() {
            this.suggestions = []
            let expression = this.functionType
            expression = expression.replace(/\s/g, '');

            // Split the expression using the operators (+, -, *, /, etc.) as separators
            var values = expression.split(/([-+*/<>=!]+|&&|\|\||\(|\)|,|if|else|{|})/);
            this.suggestions = this.tempKeys.map(data => data.toString().includes(values[values.length - 1]) ? data.replace(" ","__") : null).filter(d => d != null)
        },
        async handleSave() {
            const vals = [];
            if(!this.funcName){
                this.$toasted.error("Please fill all the fields")
                return;;
            }
            this.allFields.map(d => {
                if (d.business_object_name == this.selectedBObject) {
                    vals.push({ ...d })
                }
            })
            const valu = { business_object_name: this.selectedBObject, fieldName: this.funcName, fieldFunc: this.functionType, lastUpdatedAt: new Date(), lastUpdatedBy: LocalUtils.getEmail() }
            vals.push(valu)
            console.log(vals)
            await Server.saveCustomFields(this.selectedBObject, vals)
            this.$toasted.success("Successfully saved the fields")
            this.openModal=false;
            window.location.reload()
        },
        handleAddOp() {
            this.functionRows.push({ selectedFunc: '', selectedKey: '', selectedOp: '', selectedValue: '' })
            this.ops.push("")
        },
        matches() {
            return this.suggestions.filter((str) => {
                return str.indexOf(this.selection) >= 0;
            });
        },


        //The flag
        openSuggestion() {
            return this.selection !== "" &&
                this.matches.length != 0 &&
                this.open === true;
        },
        async handleBusinessObjectSelectTemp(bOj) {
            const data = await Server.getConfiguratorKeys(bOj)
            this.tempKeys = (data.data.keys.map((d) => d));
            if (bOj == "ticket")
                this.tempKeys.unshift("AgeOfTickets")
            return this.tempKeys

        },
        handleAddOperetor() {
            this.functionRows.push({ selectedFunc: '', selectedKey: '', selectedOp: '', selectedValue: '' })
        },
        async handleKeySelect(i) {
            const res = await Server.getValue(this.functionRows[i]['selectedKey'], this.selectedBObject);
            this.values = [...res.data]
            var ans = false
            var dateFormat = 'DD-MM-YYYY';

            if (this.tempValues) {
                this.tempValues[index].map(data => {
                    if (moment(moment(data).format(dateFormat), dateFormat, true).isValid()) {
                        ans = true;
                    }
                })
            }
            if (ans) {
                this.tempValues[index].unshift("TODAY()")
            }
        },
        async handleInit(){
            this.allFields = []
        var creds = JSON.parse(localStorage.getItem("ALL_CREDS"))
        this.bObjectList = creds.map(data => data.business_object_name)
        // this.suggestions = this.bObjectList
        const customFields = await Server.getCustomFields()
        console.log(customFields.data.data)
        customFields.data.data.map((d) => {
            d.custom_fields.map(o => {
                this.allFields.push({ ...o, business_object_name: d.business_object_name })
            })
        })
        }
    },

    async mounted() {
      this.handleInit()
    },
}
</script>
<style scoped lang="scss">.mycard {
    padding: 20px;
}</style>