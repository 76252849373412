<template>
  <div>

    <modal v-if="showAppsModal" class="modal-search" id="searchModal" :centered="true" @close="showAppsModal = false"
      :show-close="true" :show="showAppsModal">
      <div class="headers" slot="header">
        <div class="title">
        </div>
      </div>
      <Apps></Apps>
    </modal>
    <modal v-if="showApiIngestion" class="modal-search" id="searchModal" :centered="true"
      @close="showApiIngestion = false" :show-close="true" :show="showApiIngestion">
      <div class="headers" slot="header">
        <div class="title">
        </div>
        <div>
          <h2 style="text-align: center;">API Ingestion</h2>
          <h4 style="text-align: center;">Here is your token</h4>
          <div style="border: 1px dotted #E46651;padding: 10px;" class="token">
            {{ token.token }}

          </div>
          <div style="display: flex;align-items: center;justify-content: center;flex-direction: column;margin-top: 10px;">
            <span>
              You can upload your data in two ways
            </span>
            <div style="display: flex;align-items: center;justify-content: space-between;margin-top: 10px;">

              <button style="position: block;;padding: 10px;text-align: center;">
                Single Upload
              </button>
              <button style="position: block;padding: 10px;text-align: center;">
                Bulk Upload
              </button>
            </div>

          </div>
        </div>
      </div>
    </modal>
    <div class="card" style="margin-left: 0px;">
      <div style="justify-content: space-between;padding:20px;margin: 0px;align-items: center;" class="row">
        <div>
          <span style="font-size: 26px;font-weight: 700;">Good {{ timeofday }}, <span
              style="text-transform: capitalize;">{{ email.split("@")[0] }} </span> 👋</span>
          <br />
          <span style="font-size: 15px;font-weight: 500;">
            🚀 Connect your apps with AppEQ and see valuable insights
          </span>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-4">
        <card>
          <span>Total Data</span>
          <div style="justify-content: space-between; padding: 4px 15px" class="row">
            <h2>{{ totalData }}</h2>
            <div class="">
              <img style="height: 26px; margin-right: 10px" v-bind:src="'https://s2.googleusercontent.com/s2/favicons?domain_url=https://' +
                'zendesk' +
                '.com&sz=48'
                " />
              <img style="height: 26px; margin-right: 10px" v-bind:src="'https://s2.googleusercontent.com/s2/favicons?domain_url=https://' +
                'docs.google.com/spreadsheets/u/0/' +
                '.com&sz=48'
                " />
              <img style="height: 26px; margin-right: 10px" v-bind:src="'https://s2.googleusercontent.com/s2/favicons?domain_url=https://' +
                'salesforce' +
                '.com&sz=48'
                " />
            </div>
          </div>
        </card>
      </div>
      <div class="col-4">
        <card>
          <span>Total Accounts</span>
          <div style="justify-content: space-between; padding: 4px 15px" class="row">
            <h2>{{ totalUpdatedData }}</h2>
            <div class="">
              <img style="height: 26px; margin-right: 10px" v-bind:src="'https://s2.googleusercontent.com/s2/favicons?domain_url=https://' +
                'zendesk' +
                '.com&sz=48'
                " />
              <img style="height: 26px; margin-right: 10px" v-bind:src="'https://s2.googleusercontent.com/s2/favicons?domain_url=https://' +
                'docs.google.com/spreadsheets/u/0/' +
                '.com&sz=48'
                " />
              <img style="height: 26px; margin-right: 10px" v-bind:src="'https://s2.googleusercontent.com/s2/favicons?domain_url=https://' +
                'salesforce' +
                '.com&sz=48'
                " />
            </div>
          </div>
        </card>
      </div>
      <div class="col-4">
        <card>
          <span>Total number of users</span>
          <div style="justify-content: space-between; padding: 4px 15px" class="row">
            <h2>{{ totalUser && totalUser.data ? totalUser.data.length : 'NA' }}</h2>
            <div class="">
              <img style="height: 26px; margin-right: 10px" src="../assets/group-of-men.png" />
            </div>
          </div>
        </card>
      </div>

      <div class="col-12">
        <card>
          <h3 id="table-heading">Connected Enterprise Apps
            <!-- <button v-if="isAdmin" @click="handleOpen()" class="connectBtn" style="">
              <i style="padding-bottom: 0px;" class="tim-icons icon-simple-add"></i>
              &nbsp;
              <span>Connect</span>
            </button> -->


          </h3>

          <!-- <ResetDelete></ResetDelete> -->
          <div style="display:flex;align-items: center;justify-content: center;min-height: 100px;"
            class="table-responsive">

            <base-table v-if="table1.data.length != 0" :loadingStatus="isLoading" :data="table1.data" :allData="allData" :columns="table1.columns">
            </base-table>
            <div v-if="isLoading" class="loading">
              <img src="../assets/infinity_blue.svg" />
            </div>
            <div v-if="!isLoading && table1.data.length == 0"
              style="display: flex;align-items: center;justify-content: center;flex-direction: column;">
              <!-- <span  v-if="table1.data.length==0">No data is connected</span> -->
              <button v-if="table1.data.length == 0" @click="handleOpen()" class="connectBtn" style="">Add</button>

            </div>


          </div>
        </card>
      </div>
    </div>
  </div>
</template>
<script>
import { BaseTable } from "@/components";
import { Pie } from "vue-chartjs";
import ResetDelete from './ResetDelete.vue';
import Modal from "@/components/Modal";
import { Logo } from "../assets/appEQ_favicon.png"
import Apps from "./AppsList.vue"
const tableColumns = [
  "Business Object",
  "Source",
  "Bi-Directional",
  "Data Synced At",
  "Added By",
  "Action",
];
let tableData = [];
import Server from "../components/API/Server";
import LocalUtils from "../components/Utils/LocalUtils";
export default {
  name: "TableList",

  components: {
    Pie,
    Modal,
    BaseTable,
    Apps,
    ResetDelete
  },
  // async mounted() {
  //   try {
  //     if (LocalUtils.getEmail()) {
  //       this.isLoading = true;
  //       this.totalUser = await Server.getAllUsers();
  //       const data = await Server.googleSheetCreds();
  //       const businessRes = await Server.getBusinessDataObs();
  //       let occupiedBuisnessObj = [];


  //       if (data.data) {
  //         localStorage.setItem("ALL_CREDS", JSON.stringify(data.data))
  //         this.table1.data = []
  //         await Promise.all(data.data.map(async (d, index) => {
  //           occupiedBuisnessObj.push(d.business_object_name);
  //           if (occupiedBuisnessObj) {
  //             localStorage.setItem("occupiedBuisnessObj", JSON.stringify(occupiedBuisnessObj));
  //           }
  //           const data = (await Server.queryAllData({ business_object_name: d.business_object_name,appeq_supported_application:d.appeq_supported_application }))["data"]
  //           this.table1.data.push({ id: index + 1, source: d.appeq_supported_application, "total data": data, integration: "Appeq to " + d.appeq_supported_application, "business object": d.business_object_name, linked: "Yes", email: d.email, sheetUrl: d.sheet_url, ...d })
  //         }))
  //       }
  //       if (businessRes.data) {
  //         this.allData = businessRes.data;
  //         businessRes.data.map((d) => {
  //           if (!occupiedBuisnessObj.includes(d.business_object_name)) {
  //             this.table1.data.push({ id: d.business_object_id, "business object": d.business_object_name,appeq_supported_application:d.appeq_supported_application, linked: "No" });
  //           }
  //         })
  //       }
  //       this.totalData = (await Server.queryAllData({}))["data"]
  //       var today = new Date()
  //       var dd = String(today.getDate()).padStart(2, '0');
  //       var mm = String(today.getMonth() + 1).padStart(2, '0'); //January is 0!
  //       var yyyy = today.getFullYear();
  //       today = mm + '-' + dd + '-' + yyyy;
  //       this.totalUpdatedData = (await Server.queryAllData({ "CreatedDate": { "$gte": today } }))["data"]
  //       this.beforeToday = this.totalData - this.totalUpdatedData
  //       this.isLoading = false;
  //     }
  //   } catch (error) {
  //     this.isLoading = false;
  //   }
  // },
  async mounted() {
    try {
      if (LocalUtils.getEmail()) {
        this.isAdmin = localStorage.getItem("IS_ADMIN") === 'true' ? true : false;

        this.email = LocalUtils.getEmail()
        var today = new Date()
        var curHr = today.getHours()

        if (curHr < 12) {
          this.timeofday = 'morning'
        } else if (curHr < 18) {
          this.timeofday = 'afternoon'
        } else {
          this.timeofday = 'evening'
        }

        this.isLoading = true;
        this.totalUser = await Server.getAllUsers();
        let data;
          data = await Server.googleSheetCreds();
        const businessRes = await Server.getBusinessDataObs();
        let occupiedBuisnessObj = [];
        // const domains = await Server.getAllowedDomains();
        this.totalDomains = [];
        if (data.data) {
          localStorage.setItem("ALL_CREDS", JSON.stringify(data.data));
          this.table1.data = [];
          await Promise.all(
            data.data.map(async (d, index) => {
              occupiedBuisnessObj.push(d.business_object_name);
              if (occupiedBuisnessObj) {
                localStorage.setItem(
                  "occupiedBuisnessObj",
                  JSON.stringify(occupiedBuisnessObj)
                );
              }
              this.table1.data.push({
                id: index + 1,
                source: d.appeq_supported_application,
                "total data": 0,
                integration: "Appeq to "+d.appeq_supported_application,
                "business object": d.business_object_name,
                "added by": d.email,
                linked: "Yes",
                email: d.email,
                lastupdatedat:"asd",
                sheetUrl: d.sheet_url,
                ...d,
              });
            })
          );
          this.isLoading = false;
          this.totalData=  (
                await Server.queryAllData({
                })
              )["data"];
              
        }
        if (businessRes.data) {
          this.allData = businessRes.data;
          businessRes.data.map((d) => {
            if (!occupiedBuisnessObj.includes(d.business_object_name)) {
              this.table1.data.push({ id: d.business_object_id, "business object": d.business_object_name, appeq_supported_application: d.appeq_supported_application, linked: "No" });
            }
          })
        }
        const customOrder = ["Contacts", "accounts", "pulse", "tasks", "ticket"];
        console.log(this.table1.data)
        // Sort the array based on the custom order
        this.table1.data.sort((a, b) => {
          const indexA = customOrder.indexOf(a["business_object_name"]);
          const indexB = customOrder.indexOf(b["business_object_name"]);
          if (indexA !== -1 && indexB !== -1) {
            return indexA - indexB;
          } else if (indexA !== -1) {
            return -1;
          } else if (indexB !== -1) {
            return 1;
          } else {
            return a["business_object_name"].localeCompare(b["business_object_name"]);
          }
        });

        var today = new Date();
        var dd = String(today.getDate()).padStart(2, "0");
        var mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
        var yyyy = today.getFullYear();
        today = mm + "-" + dd + "-" + yyyy;
        this.totalUpdatedData = (
          await Server.queryAllData({ business_object_name:"accounts" })
        )["data"];
        this.beforeToday = this.totalData - this.totalUpdatedData;
      }
    } catch (error) {
      this.isLoading = false;
    }
  },
  data() {
    return {
      totalData: 0,
      timeofday: "Morning",
      showModal: false,
      isAdmin: false,
      totalDomains: [],
      showAppsModal: false,
      email: "",
      totalUpdatedData: "",
      showApiIngestion: false,
      beforeToday: "",
      allData: [],
      showMoreActions: false,
      totalUser: {},
      token: '',
      table1: {
        title: "Linked Accounts",
        columns: [...tableColumns],
        data: [],
      },
      pieData: {
        labels: ["VueJs", "EmberJs", "ReactJs", "AngularJs"],
        datasets: [
          {
            backgroundColor: ["#41B883", "#E46651", "#00D8FF", "#DD1B16"],
            data: [40, 20, 80, 10],
          },
        ],
      },
      options: {
        responsive: true,
        maintainAspectRatio: false,
      },
      isLoading: false,
    };
  },
  methods: {

    async handleOpenApiIngestion() {
      this.showApiIngestion = true;
      const data = await Server.callIngestAuth()
      this.token = data
    },
    handleOpen() {
      // this.showAppsModal = true;
      this.$router.push({path:'/integrations',query:{...this.$route.query}})

    },
    handleClickModal() {
      this.showModal = !this.showModal;
    },
    getIcon(context_link) {
      try {
        console.log(context_link)
        if (context_link == null)
          return 'https://s2.googleusercontent.com/s2/favicons?domain_url=https://' + 'zendesk.com' + '&sz=128';
        else if (context_link.toString().split("@").length == 0)
          return context_link
        else
          return 'https://s2.googleusercontent.com/s2/favicons?domain_url=https://' + context_link + '&sz=128';
      } catch (e) {
        console.log(e);
      }
    },
    outsideClick() {
      this.showModal = false;

    },
    checkClose() {
      this.showModal = false;
    },
  },
};
</script>
<style scoped lang="scss">
.loading {
  display: flex;
  align-items: center;
  position: absolute;
  top: 0px;
  right: 0px;
  bottom: 0px;
  width: 100%;
  z-index: 9999999;
  justify-content: center;
  margin-top: 30px;
}

.data-cards {
  display: flex;
  margin-top: 10px;
  align-items: center;

  &.users {
    display: flex;
  }

  img {
    &:hover {
      scale: 1.2;
      transition: 0.2s ease-in-out;
    }
  }
}

.usercard {
  background-color: blue;
  height: 20px;
  width: 20px;
  text-align: center;
  color: white;
  border-radius: 50%;
}

#table-heading {
  text-align: center;
  margin-bottom: -5px;
  padding: 10px;
  background: #f8f8f8;
  font-weight: 550;
  font-size: 18px;
}

.customerListCard {
  width: 150px;
  overflow-x: scroll;

}

.accountsLogoWrapper {
  overflow-y: scroll;
  max-height: 600px;
  padding: 10px;
}

.moreAction {
  position: absolute;
  right: 60px;
  top: 22px;

  button {
    height: 30px;
    border-radius: 6px;
    width: 120px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-weight: 510;

    i {
      font-size: 15px;
    }

    &:hover {
      opacity: 0.9;
    }
  }
}

.connectBtn {
  position: absolute;
  width: 150px;
  height: 30px;
  right: 30px;
  border-radius: 5px;
  font-size: 14px;
  margin-top: -2px;
  background-color: #4e5fff;
  color: white;
}

.apiBtn {
  position: absolute;
  width: 150px;
  height: 30px;
  right: 190px;
  top: 25px;
  border-radius: 5px;
  font-size: 14px;

  background-color: #4e5fff;
  color: white;
}</style>
