<template>
  <div ref="treeContainer"></div>
</template>

<script>
import * as d3 from "d3";

export default {
  name: "HierarchyTree",
  data() {
    return {
      linkedNodes: {
        name: "Account",
        children: [
          { name: "Tickets" },
          { name: "Deals" },
          { name: "Contacts" },
          { name: "Tasks" },
        ],
      },
      unlinkedNodes: [
        { name: "Transactions" },
        { name: "NPS" },
      ],
    };
  },
  mounted() {
    this.drawTree();
  },
  methods: {
    drawTree() {
      const width = 1000;
      const height = 600;

      const svg = d3
        .select(this.$refs.treeContainer)
        .html("") // Clear previous SVG content
        .append("svg")
        .attr("width", width)
        .attr("height", height);

      // Tree layout for linked nodes
      const treeLayout = d3.tree().size([height - 100, width / 2 - 100]);

      const root = d3.hierarchy(this.linkedNodes);

      treeLayout(root);

      const g = svg.append("g").attr("transform", "translate(50,50)");

      // Links
      g.selectAll(".link")
        .data(root.links())
        .enter()
        .append("line")
        .attr("class", "link")
        .attr("x1", d => d.source.y)
        .attr("y1", d => d.source.x)
        .attr("x2", d => d.target.y)
        .attr("y2", d => d.target.x)
        .attr("stroke", "#ccc");

      // Nodes
      const nodes = g
        .selectAll(".node")
        .data(root.descendants())
        .enter()
        .append("g")
        .attr("class", "node")
        .attr("transform", d => `translate(${d.y},${d.x})`);

      nodes
        .append("rect")
        .attr("width", 100)
        .attr("height", 40)
        .attr("x", -50)
        .attr("y", -20)
        .attr("rx", 10)
        .attr("ry", 10)
        .attr("fill", "#E6E6FA");

      nodes
        .append("text")
        .attr("dy", 3)
        .attr("text-anchor", "middle")
        .text(d => d.data.name);

      // Unlinked nodes
      const unlinked = svg
        .append("g")
        .attr("class", "unlinked")
        .attr("transform", `translate(${width / 2 + 100}, 100)`);

      unlinked
        .selectAll(".unlinked-node")
        .data(this.unlinkedNodes)
        .enter()
        .append("g")
        .attr("class", "unlinked-node")
        .attr("transform", (d, i) => `translate(0, ${i * 60})`)
        .each(function(d) {
          const node = d3.select(this);
          node
            .append("rect")
            .attr("width", 150)
            .attr("height", 40)
            .attr("x", -75)
            .attr("y", -20)
            .attr("rx", 10)
            .attr("ry", 10)
            .attr("fill", "#E6E6FA");

          node
            .append("text")
            .attr("dy", 3)
            .attr("text-anchor", "middle")
            .text(d.name);

          node
            .append("circle")
            .attr("cx", 65)
            .attr("cy", 0)
            .attr("r", 10)
            .attr("fill", "#1E90FF");
        });

      // Add title for unlinked section
      svg
        .append("text")
        .attr("x", width / 2 + 100)
        .attr("y", 50)
        .attr("class", "unlinked-title")
        .attr("text-anchor", "middle")
        .text("Unlinked Business Objects");
    },
  },
};
</script>

<style>
.node rect {
  fill: #E6E6FA;
  stroke: #ccc;
  stroke-width: 1.5px;
}

.unlinked-node rect {
  fill: #E6E6FA;
  stroke: #ccc;
  stroke-width: 1.5px;
}

.link {
  fill: none;
  stroke: #ccc;
  stroke-width: 1.5px;
}

.unlinked-title {
  font-weight: bold;
  font-size: 16px;
}
</style>
