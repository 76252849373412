<template>
    <div>
        <div style="display: flex; justify-content: space-between; border-bottom: 1px solid lightgray; padding-bottom: 10px; padding-top: 3px; align-items: center;">
            <div></div>
            <div>
                <h2 style="margin: 0px 0px 0px 10px;
    font-size: 30px;
    font-weight: 400;">Add Growth Hub Data</h2>
            </div>
            <div>
                <img src="../icons/Close.svg" style="height: 1.2vw; cursor: pointer;" @click="handleClose" alt="">
            </div>
        </div>
        <div style="display: flex; justify-content: center; align-items: center;">
            <div>
                <div style="display: flex; flex-direction: column; justify-content: center; height: 32vh; width: 46vw;">
                    <div style="display: flex; justify-content: space-between;">
                        <div style="display: flex;padding: 10px;align-items: center;">
                        <label style="width: 131px; font-weight: 600;">Type</label>
                        <div style="display: flex; justify-content: space-between; width: 12vw;">
                            <div style="display: flex; justify-content: space-between; align-items: center; width: 4vw;">
                                <input type="radio" name="type" value="Upsell" v-model="growthHubData.Type" id="">
                                <div>Upsell</div>
                            </div>
                            <div style="display: flex; justify-content: space-between; width: 5.5vw; align-items: center;">
                                <input type="radio" name="type" value="Cross-sell" v-model="growthHubData.Type" id="">
                                <div>Cross-sell</div>
                            </div>
                        </div>
                    </div>
                    <div style="display: flex;padding: 10px;align-items: center; width: 50%;">
                        <label style="width: 131px; font-weight: 600;">Name</label>
                        <input type="text" style="width: 100%; border: 1px solid rgb(232, 232, 232); border-radius: 8px;padding: 6px;" v-model="growthHubData.productName">
                    </div>
                    </div>
                    <div style="display: flex;padding: 10px;align-items: center;">
                        <label style="width: 131px; font-weight: 600;">Description</label>
                        <textarea name="" v-model="growthHubData.Description" id="" style="width: 100%; border: 1px solid rgb(232, 232, 232); border-radius: 8px; resize: vertical; max-height: 130px; min-height: 40px;" rows="2"></textarea>
                    </div>
                    <div style="display: flex;padding: 10px;align-items: center;">
                        <label style="width: 131px; font-weight: 600;">Price Notes</label>
                        <textarea name="" v-model="growthHubData.priceNotes" id="" style="width: 100%; border: 1px solid rgb(232, 232, 232); border-radius: 8px; resize: vertical; max-height: 130px; min-height: 40px;" rows="2"></textarea>
                    </div>
                </div>
            </div>
        </div>
        <div style="display: flex; justify-content: end;">
            <button @click="handleSave" style="width: 14%; height: 40px; border-radius: 7px;background: #6363ff;">Save</button>
        </div>
    </div>
</template>
<script>
import Server from "./API/Server";
export default {
    methods: {
        handleClose(){
            this.$emit('close');
        },
        async handleSave(){
            try {
                if(!this.growthHubData.productName || this.growthHubData.productName==''){
                    this.$toasted.error('Please provide product name');
                    return;
                }
                let res = await Server.addProductCatalogue(this.growthHubData);
                console.log(res);
                if(res.code!==200){
                    this.$toasted.error('Error occured while saving data');
                }
                this.$toasted.success('Data Saved Successfully!');
                this.$emit('save');
            } catch (error) {
                
            }
        }
    },
    mounted(){
        if(this.prevData.Type && this.prevData.productName){
            this.growthHubData = this.prevData;
        }
    },
    props: {
        prevData: {}
    },
    data(){
        return {
            growthHubData: {
                productName: '',
                Description: '',
                priceNotes: '',
                Type: 'Upsell'
            }
        }
    }
}
</script>