<template>
    <div class="">
        <h2 style="text-align: center;">Advanced Configuration</h2>
        <input v-model="name" type="text" placeholder="* Name" style="border-bottom: 1px solid #efefef;height: 48px;border-left: none !important;width: 30%;" >
        <div v-for="url of urls" style="display: flex;align-items: center;margin-top: 20px;" class="">
            <Multiselect style="border: none;" v-model="url['operetor']" :options="['Equal To', 'Contains']" />
            <input  v-model="url['url']" style="border: 1px solid #efefef;height: 48px;border-left: none !important;" type="text" placeholder="Enter Url">

        </div>
        <div style="display: flex;align-items: center;justify-content: flex-end;margin-top: 20px;" class="">
            <span @click="openUrl" style="cursor: pointer;color: blue;font-weight: 600;font-size: 16px;">
                Add Url
            </span>
        </div>
        <div class="" style="display: flex;align-items: center;justify-content: flex-end;position: absolute;bottom: 20px;width: 100%;right: 20px;">
            <button @click="save" style="width: 10%;height: 35px;">Save</button>

        </div>
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
export default {
    components: {
        Multiselect
    },
    methods:{
        openUrl(){
            this.urls.push({url:"",operetor:""})
        },
        save(){
            if(!this.name){
                this.$toasted.error("Please fill all the fields")
                return
            }
            this.$emit("save",{url:this.urls,name:this.name})
        }
    },
    data(){
        return{
            name:"",
            urls:[{url:"",operetor:""}]
        }
    }
}
</script>
<style lang="scss" scoped></style>