<template>
    <div style="padding: 20px;" class="card">
        <div style="margin: 12px; height: 60px; display: flex; justify-content: start; align-items: flex-start;">
            <img src="../assets/img/Business Objects.png" style="height: 2.5vw; margin-right: 1vw;" alt="">
            <h3 style="border-bottom: 1px solid lightgrey; padding-bottom: 8px;">Integrations</h3>
        </div>
        <div  style="display: flex; flex-direction: column;padding: 30px 100px; width: 100%; margin-left: 0%;">
            <div style="display: flex; flex-direction: column;">
                <div style="color: #373535; font-weight: 600;">Connected integrations</div>
                <div v-for="obj of objects" class="cardNew">
                    <div style="flex: 0.05;" class="">

                    <img v-if="obj.appeq_supported_application == 'spreadsheet'"
                        src="../assets/img/Google_Sheets_2020_Logo.svg" style="height: 43px; margin-left: 1rem;" alt="">
                        <img v-else-if="obj.appeq_supported_application.toLowerCase().includes('api')" src="../assets/img/API2.png" style="height: 26px; margin-left: 1rem;" alt="">
                        <img v-else-if="obj.appeq_supported_application.toLowerCase().includes('appequpload')" src="../assets/appeq.png" style="height: 26px; margin-left: 1rem;" alt="">
                    <img style="height: 43px; margin-left: 1rem;" src=""
                        v-bind:src="('https://s2.googleusercontent.com/s2/favicons?domain_url=https://' + (obj['appeq_supported_application'].toLowerCase().includes('zoho') ? 'zoho' : obj['appeq_supported_application']) + '.com&sz=48')"
                        v-else />
                    </div>
                    
                        <div style="margin-left: 2rem; margin-right: 2rem;flex: 1;">
                        <div style="display: flex;align-items: center;" class="">
                           <span style="font-weight: 700;text-transform: capitalize;">{{ obj.appeq_supported_application }}</span>
                           <button v-if="obj.crm" style="width: 65px;text-align: center;height: 20px;margin-left: 10px;border-radius: 8px;background-color: rgb(169, 167, 167);">CRM</button>

                       </div>
                       <p v-if="obj.crm" style="color: #414141; font-size: 0.6vw;">Connect with CRM for bi directional sync with AppEQ for getting data insights, dashboard and automations.</p>
                       <p v-else-if="obj.appeq_supported_application=='zendesk'" style="color: #414141; font-size: 0.6vw;">Connect with customer issues and ticketing platform for directly syncing with AppEQ for getting data insights, dashboard and automations.</p>
                       <p v-else-if="obj.appeq_supported_application=='mixpanel'" style="color: #414141; font-size: 0.6vw;">Connect with your product analytics for directly syncing with AppEQ for getting data insights, dashboard and automations.</p>
                       <p v-else-if="obj.appeq_supported_application.toLowerCase().includes('api')" style="color: #414141; font-size: 0.6vw;"> Connect with your applications directly by sending data through our secured API interface.</p>
                        
                       <p v-else-if="!obj.crm" style="color: #414141; font-size: 0.6vw;">Connect with your operational spreadsheet and directly
                            sync with AppEQ for getting data insights, dashboard and automations.</p>
                    </div>
                    <div style="display: flex; flex-direction: column; width: 14rem;flex: 0.2;">
                        <button @click="openApplication(obj)" style="width: 90px;
    height: 25px;
    border-radius: 6px; background: white;
                        font-size: 12px;

    color: black;
    border: 1px solid lightgrey;">View</button>
                        <span style="font-size: 10px;text-transform: capitalize;text-align: right;margin-top: 10px;margin-right: 30px;">By {{ obj.email.split("@")[0] }}</span>
                    </div>
                </div>
                <div style="display: flex;flex-direction: column;align-items: center;justify-content: center;" class="" v-if="objects.length==0">
                    <img src="../assets/nodata.svg" style="height: 200px;" />
                    <div>No App Connected. Please Connect</div>
                </div>

            </div>
            <div style="display: flex; flex-direction: column;">
                <div style="color: #373535; font-weight: 600;">Available</div>
                <div  v-if="isConnected(app.apps.productName)" v-for="app of appsList" class="cardNew">
                    <img  :src="app.apps.logoURL" style="height: 43px;width: 100%;width: 50px; margin-left: 1rem;flex: 0.05;" alt="">
                    <div style="margin-left: 2rem; margin-right: 2rem;flex: 1;">
                       <div style="display: flex;align-items: center;" class="">
                           <span style="font-weight: 700;">{{ app.apps.productName }}</span>
                       </div>
                        <p style="color: black; font-size: 0.65vw;">Connect with customer issues and ticketing platform for
                            directly syncing with AppEQ for getting data insights, dashboard and automations.</p>
                    </div>
                    <div style="display: flex; flex-direction: column; margin-right: 1rem;flex: 0.2;">
                        <button @click="connect(app.apps.productName, app.apps)" 
                            style="width: 90px;
    height: 25px;font-size: 12px;
    border-radius: 6px;">Connect</button>

                        <!-- <span style="font-size:11px; color: red;">Expired since 3 days ago</span> -->
                    </div>
                </div>

            </div>
        </div>
        <Modal v-if="toOpen" @close="toOpen = false" class="modal-search" id="searchModal" :centered="true"
            :show-close="true" :show="toOpen">
            <div class="headers" slot="header">
                <div class="title">
                </div>
            </div>
            <AppsList :selectedProp="itemToOpen" :openFromNew="true" :imageUrl="itemToOpen" />


        </Modal>
            <SalesforceIntegration :creds="openView" v-if="openSalesforceModal" @close="openSalesforceModal=false;" @editToOpen="handleEdit($event)" />
    </div>
</template>
<script>
import Server from '../components/API/Server';
import DescPage from '../components/DescPage.vue';
import LocalUtils from '../components/Utils/LocalUtils';
import Modal from '../components/Modal.vue';
import AppsList from './AppsList.vue';
import SalesforceIntegration from "./SalesforceIntegration.vue";
export default {
    methods: {
        connect(item, obj) {
            localStorage.setItem("Business_obj_name", obj.business_object_name)
            this.toOpen = item;
            this.itemToOpen = obj

        },
        isConnected(item) {
            return this.objects.filter(d => item.toLowerCase().includes(d.appeq_supported_application.toLowerCase())).length == 0;
        },
        handleEdit(e){
            console.log(e);
            this.toOpen = e;
            this.itemToOpen = e;
        },
        openApplication(item) {
            console.log(item);
            this.openSalesforceModal = true;
            this.openView=item
      
        },
    },
    data() {
        return {
            objects: [],
            notconn: [],
            appsList: [],
            openSalesforceModal: false,
            toOpen: "",
            itemToOpen: {},
            openView:{},
        };
    },
    async mounted() {
        const creds = (await Server.googleSheetCredentials(LocalUtils.getEmail())).data;
        creds.map(d => {
            if (this.objects.filter(o => o.appeq_supported_application == d.appeq_supported_application).length == 0) {
                if(d.appeq_supported_application=="salesforce" || d.appeq_supported_application=="hubspot")
                d['crm']=true;
                d['business_object_name']=[d['business_object_name']]
                d['root']=[d]
                this.objects.push(d);
            }
            else {
                const idx=this.objects.findIndex(o => o.appeq_supported_application == d.appeq_supported_application)
                this.objects[idx].business_object_name.push(d.business_object_name)
                this.objects[idx].root.push(d)

            }
        });
        let obj = {
        "apps": {
            "business_object_name": [],
            "defaultURL": [
                "https://docs.google.com/spreadsheets/u/0/",
                "docs.google.com"
            ],
            "domain": [
                "docs",
                "docs"
            ],
            "enabled": true,
            "g2Category": "Others",
            "id": "30",
            "logoURL": "https://upload.wikimedia.org/wikipedia/commons/a/ae/Google_Sheets_2020_Logo.svg",
            "org_id": "9e843969-521c-4700-b287-681c8e868f62",
            "productName": "Upload"
        }
    };
        const data = await Server.getApps();
        this.appsList = [obj, ...data.data];
        this.appsList.map((data) => {
            if (testArr[data.apps.g2Category]) {
                let disavled = false;
                //     if(creds.filter(cred=>cred.business_object_name==data.apps.productName).length!=0){
                //     disavled=true   
                // }
                testArr[data.apps.g2Category]["items"].push({ ...data.apps, tooltip: false, disabled: disavled });
            }
            else {
                let disavled = false;
                console.log(data.apps.productName);
                // if(creds.filter(cred=>data.apps.productName.toLowerCase().includes(cred.appeq_supported_application.toLowerCase())).length!=0){
                //     disavled=true   
                // }
                testArr[data.apps.g2Category] = {
                    items: [{ ...data.apps, tooltip: false, disabled: disavled }],
                    key: data.apps.g2Category,
                };
            }
        });
        testArr["API Ingestion"] = {
            items: [{ logoURL: "https://app.appeq.ai/img/appeq.cc5b93eb.png", productName: "API Ingestion", disabled: false }],
            key: "API Ingestion"
        };
        this.mainAppLits = testArr;
        this.appsList = testArr;
    },
    components: { DescPage, Modal, AppsList, SalesforceIntegration }
}
</script>
<style lang="scss" scoped>

.cardNew{
    display: flex; 
    align-items: center; 
    background-color: #E9F1FB !important;
    background: white; 
    border-radius: 4px; height: 100px;
    padding: 12px;
    box-shadow: 0 8px 32px 0 rgba( 31, 38, 135, 0.37 );
    margin-bottom: 20px; margin-top: 10px;
}

</style>