<template>
    <div class="playbook card">
        <div class="playbook__header">
            <img class="playbook__header__img" src="../assets/playbook.png" />
            <div style="width: 60%;margin-left: 20px;" class="">
                <h3 class="playbook__header__title">Nudges & Playbook</h3>
                <span class="playbook__header__desc">Nudge & Playbook encompasses in-app notifications and task creation with automated assistance capabilities, triggered either by pre-configured conditions or manually initiated.
</span>
            </div>
        </div>
        <div style="display: flex;align-items: center;justify-content: space-between; width: 101%; margin-top: 1rem;">
            <GlobalTabs @selectTab="handleSelectTab($event)" :notIdx="false" :tabs="tabs"/>
            <AddButtonGlobal @button="handleNewNudge" />
        </div>
        <div class="playbook__body">
            <PlayBookCustom v-if="selectedTab==0" />
            <SystemNudge v-if="selectedTab==1" />

        </div>
        <div class="" v-if="openNudge">
            <NudgesConfiguration @save="handleSaveNudge($event)" :isEditingIdx="-1" @close="openNudge=false" :nudge="nudgeData" />
        </div>
    </div>
</template>

<script>
import NudgesConfiguration from '../components/NudgesConfiguration.vue';
import PlayBookCustom from '../components/PlayBookCustom.vue';
import Server from '../components/API/Server';
import SystemNudge from '../components/SystemNudge.vue';
import LocalUtils from '../components/Utils/LocalUtils';
import AddButtonGlobal from "../layout/dashboard/AddButtonGlobal.vue";
import GlobalTabs from '../layout/dashboard/GlobalTabs.vue';
export default {
    components:{
    PlayBookCustom,
    NudgesConfiguration,
    GlobalTabs,
    AddButtonGlobal,
    SystemNudge
},
    data(){
        return {
            tabs:["Custom","System"] ,
            selectedTab:0,   
            nudgeData:{},
            openNudge:false,  
        }
    },
    methods: {
        async handleSaveNudge(event){
            const data = (await Server.getNotificationSettings()).data;
            if (data && data.length > 0 && data[0].notificationSettings && data[0].notificationSettings.customNudges) {
                     event.email=LocalUtils.getEmail()
                     data[0].notificationSettings.customNudges.push(event)
                     await Server.saveNotificationSettings(data[0].notificationSettings);
            }else{
                event.email=LocalUtils.getEmail()
                let notificationSettings = {
                    "customNudges": [
                    
                    ],
                }
                notificationSettings.customNudges.push(event)
                await Server.saveNotificationSettings(notificationSettings);
            }
            console.log(this.nudgeData, event);
            this.openNudge=false
            this.$toasted.success("Nudge Added Successfully");
            window.location.reload()
        },
        handleSelectTab(index){
            this.selectedTab=index
        }   ,
        handleNewNudge(){
            console.log("asdasbdhas")
        console.log("bhegyrgreg")
        this.nudgeData={
            query:[],
            name:"",
            desc:"",
            trigger:"auto",
            tasks:[]

        }
       
            this.openNudge=true
        },
    },
    
}
</script>
<style lang="scss" scoped>
.playbook {
    padding: 20px 20px;

    &__header {
        display: flex;
        align-items: center;
        margin-top: 1vw;
        margin-left: 1.25vw;

        &__img {
            height: 55px;
        }

        &__title {
            margin-bottom: 0px !important;
        }

        &__desc {
            margin-top: 4px;

        }
    }

    &__tabs {
        display: flex;
        flex: 1;
        margin-right: 20px;
        align-items: center;
        border-bottom: 1px solid rgb(228, 224, 224);
        margin-bottom: 10px;
        margin-top: 10px;
        &__tab {
            margin-left: 20px;
            cursor: pointer;
            padding: 8px;
            font-weight: 500;
            font-size: 14px;
        }
    }
}
.selecetdTab{
    font-weight: 600;

    border-bottom: 2px solid blue;
}
</style>