<template>
    <div class="nudge_configuration">
        <h2 style="text-align: center;margin-top: 20px;margin-bottom: 10px;">Launcher configuration</h2>
        <div class="" style="position: absolute;right: 0px;top: 0px;">
            <img style="cursor: pointer;" src="../assets/img/feed_close.svg" @click="close" />
        </div>
        <Modal v-if="selectedAdvancedConf" class="preview_user_modal" id="searchModal" :centered="true"
            :show-close="true" :show="selectedAdvancedConf">
            <img src="../icons/feed_close.svg" style="position: absolute;top: 2px;right: 2px;cursor: pointer;"
                @click="selectedAdvancedConf = false" />
            <AdvancedModal v-if="activeSection.title=='WHEN'" @save="handleSaveConf($event)" :advancedConf="selectedAdvancedConf" />
            <AdvancedConfWhere v-else-if="activeSection.title=='WHERE'" @save="handleSaveConf($event)" :advancedConf="selectedAdvancedConf" />

        </Modal>
        <div class="" style="display: flex;align-items: center;justify-content: space-between;padding-right: 25px;">
            <div class="tabs">
                <div @click="handleTab(idx)" :class="selectedtab == idx ? 'selectedTab' : ''" class="tab"
                    v-for="(tab, idx) of tabs">
                    {{ tab }}
                </div>
                
            </div>
                <div class="" style="display: flex;align-items: center;">                
                        <div class="">
                            <img v-tooltip="'Delete Widget'" @click="deleteWidget" style="filter: invert(11%) sepia(94%) saturate(7149%) hue-rotate(359deg) brightness(98%) contrast(110%);cursor:pointer;height: 16px;"src="../assets/delete.svg" />
                        </div>
                </div>
        </div>
        <div style="display: flex;align-items: center;justify-content: center;">
            <img v-if="loading" src="../icons/loadingripple.svg" style="height: 120px;" />

        </div>

        <div v-if="!loading && selectedtab==0" style="padding: 40px;" class="configuration-container">
            <div class="configuration-sidebar">
                <input style="border-bottom: 1px solid #e8e8e8;" placeholder="*Name" type="text"
                    v-model="nudgeData['content']['title']">
                <!-- <input style="border-bottom: 1px solid #e8e8e8;margin-top: 20px;" placeholder="Enter Widget Desc"
                    type="text" v-model="nudgeData['content']['desc']"> -->

                <!-- Sidebar with draggable list of sections -->
                <div :class="activeSection == section ? 'selectedAction' : ''" class="section-block"
                    v-for="(section, index) in configuration.sections" :key="index" @click="setActiveSection(section)">
                    <div style="flex: 0.3;" class="">
                        <h3 style="margin-bottom: 4px;text-align: center;">{{ section.title }}</h3>
                        <p style="color: grey;font-size: 10px;text-align: center;">
                            {{ section.desc }}
                        </p>
                    </div>
                    <div style="flex: 0.4;"  class="draggto">
                        <h5 v-if="nudgeData && !nudgeData[section.fieldSaved]">Select</h5>
                        <div class="" v-else>{{ (nudgeData[section.fieldSaved]['text']) ?
                nudgeData[section.fieldSaved]['text'] : nudgeData[section.fieldSaved]['name'] }}
                        </div>
                    </div>
                    

                </div>
            </div>
            <div class="configuration-separator"></div>

            <div class="configuration-detail">
                <div style="display: flex; justify-content: space-around; width: 100%;"
                    v-if="this.activeSection && this.activeSection.title && this.activeSection.title == 'WHAT'">
                    <div>
                        <input type="radio" name="widgetType" value="announcement" v-model="widgetType"
                            @change="handleWidgetType" id="">
                        <span>Announcement</span>
                    </div>
                    <!-- <div>
                        <input type="radio" name="widgetType" value="multi tile" v-model="widgetType"
                            @change="handleWidgetType" id="">
                        <span>Multi tile</span>
                    </div> -->
                    <div>
                        <input type="radio" name="widgetType" value="dashboard" v-model="widgetType"
                            @change="handleWidgetType" id="">
                        <span>Dashboard</span>
                    </div>
                    <div>
                        <input type="radio" name="widgetType" value="tasklist" @change="handleWidgetType"
                            v-model="widgetType" id="">
                        <span>Tasklist</span>
                    </div>
                </div>
                <div
                    style="margin-left: 20px; padding: 12px; border-radius: 8px; margin-top: 25px; border-radius: 12px; border: 1px solid lightgray; padding-bottom: 20px;">
                    <h3 style="text-align: center;">Options </h3>
                    <div v-if="activeSection" class="section-configuration">
                        <!-- <input v-if="activeSection.type === 'text'" type="text" v-model="activeSection.content" /> -->

                        <div v-if="activeSection">
                            <div v-if="activeSection.title=='WHEN' || activeSection.title=='WHERE'" class="alignEnd">
                                <span @click="openAdvancedSettings" style="font-size: 0.75vw;font-weight: 600;color: blue;cursor: pointer;">Add Custom</span>
                            </div>
                            <table style="width: 100%;">
                                <th v-for="col of ['Name', 'LastModifed', 'Action']">
                                    {{ col }}
                                </th>
                                <tr v-for="(row,idx) of activeSection.options">
                                    <td v-for="col of ['Name', 'LastModifed', 'Action']">
                                        <div class="" v-if="col == 'Name'">
                                            <img style="height: 24px;margin-bottom: 5px; margin-right: 10px;"
                                            v-if="row.icon" :src="row.icon" />
                                            {{ row['name']?row['name']:row['text'] }}
                                        </div>
                                        <div class="" v-else-if="col == 'LastModifed'">

                                            {{ row['email']?row['email']:"System" }}
                                        </div>
                                        <div class="" v-else>
                                            <img v-if="!row['addedLeft']" @click="handleSectionSave(row)" src="../assets/selectAdd.png"
                                                style="height: 18px;cursor: pointer;" />
                                            <img @click="removeSection(row)" v-else src="../icons/Close.svg" style="height: 18px;cursor: pointer;"/>
                                            <img src="../assets/delete.svg" style="color: red;height: 14px;margin-left: 20px;" @click="removeConf(idx)" v-if="row['advanced']"/>

                                        </div>
                                    </td>
                                </tr>
                            </table>
                            <!-- <div
                                :style="{ cursor: activeSection.title == 'WHERE' || card.value=='advanced' ? 'pointer' : 'move',border:card.value =='advanced'?'3px solid blue':'' }"
                                    v-tooltip="activeSection.title == 'WHERE' ? 'Click to Add' : 'Drag to move'"
                                    @click="handleSectionSave(card)" v-for="card of activeSection.options"
                                    class="optioncard">
                                    <div class="cardTitle">
                                        <img style="height: 24px;margin-bottom: 5px; margin-right: 10px;"
                                            v-if="card.icon" :src="card.icon" />{{ (card.text) ? card.text : card.name
                                        }}
                                    </div>
                                    <div class="cardDescription">{{ card.description }}</div>
                                </div> -->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div v-else-if="selectedtab==1">
            <iframe id="appeqIframe" style="height: 80vh !important;border: none;" :src="iframeSrc"></iframe>
        </div>
        <div class=""v-else style="display: flex;align-items: center;justify-content: center;">
            <img src="../icons/loadingripple.svg" style="height: 120px;" />

        </div>
        <div class="personalfooter">
            <div class=""
                style="display: flex; align-items: center; width: 20%; justify-content: space-around; flex: 0.6;">
                <!-- <div style="margin-right: 10px; cursor: pointer;" v-tooltip="'Resync Nudge'" class="">
                    <img src="../assets/refresh.svg" />
                </div> -->
                <!-- <div v-tooltip="'Delete Widget'" class="" @click="deleteWidget">
                    <img style="filter: invert(11%) sepia(94%) saturate(7149%) hue-rotate(359deg) brightness(98%) contrast(110%);cursor:pointer;"
                        src="../assets/delete.svg" />

                </div> -->

            </div>
            <div class="personalfooter__btn">
                <button @click="cancelWidget" class="personalfooter__btn--cancel">Cancel</button>
                <button @click="saveWidget">Save</button>

            </div>

        </div>

    </div>

</template>

<script>
import Multiselect from 'vue-multiselect';
import { JWT } from "node-jsonwebtoken";
import Server from './API/Server';
import { operetors } from '../pages/Functions';
import { VueAutosuggest } from "vue-autosuggest";
import FilterModal from './FilterModal.vue';
import Modal from './Modal.vue';
import TaskPopup from './TaskPopup.vue';
import draggable from 'vuedraggable';
import Vue from 'vue';
import AdvancedModal from './AdvancedModal.vue';
import AdvancedConfWhere from "./AdvancedConfWhere"
export default {
    props: {
        nudge: {},
        isEditingIdx: -1,
        appeq_org_id: "",
        conf: null,
    },
    components: {
        Multiselect,
        Modal,
        TaskPopup,
        FilterModal,
        draggable,
        VueAutosuggest,
        AdvancedModal,
        AdvancedConfWhere
    },
    async mounted() {
        const widgetConf=await Server.saveWidgetConf('get',{})
        if(widgetConf && widgetConf.data && widgetConf.data.length>0){
            this.configuration=widgetConf.data[0]['configuration']
        }   
        if (this.conf) {
            console.log(this.conf, 'conf data')
            this.nudgeData = this.conf;
            this.widgetId = this.conf._id;
            this.widgetType = this.conf.widget.type
        }

        setTimeout(() => {
            document.querySelector(".nudge_configuration").style.right = "0px"

        }, 600)
        this.loading=true
        const data = (await Server.getAllUsers()).data
        this.users = data
        let optionsData = (await Server.getWidgetContent()).data;
        console.log(optionsData, 'options data imp');
        this.whatOptions = optionsData;
        this.loading=false
        this.activeSection=this.configuration['sections'][0]
        if(!this.conf){
            for(let i=0;i<this.whatOptions.length;i++){
            if(this.whatOptions[i].type=='announcement'){
                this.activeSection.options.push(this.whatOptions[i]);
            }
        }
        this.activeSection.options.map(d => {
                        d.icon = "/img/announcment.png"
                        return d
                    })
        }else{
            for(let i=0;i<this.whatOptions.length;i++){
                if(this.whatOptions[i].type==this.widgetType.toLowerCase()){
                    this.activeSection.options.push(this.whatOptions[i]);
                }
            }
            for(let i=0;i<this.activeSection.options.length;i++){
                if(this.conf.widget._id==this.activeSection.options[i]._id){
                    this.activeSection.options[i]['addedLeft'] = true;
                }
            }
        }
    },
    data() {
        return {
            selectedAdvancedConf: null,
            loading:false,
            iframeSrc: "",
            widgetId: '',
            whoList: [
                "Static Users",
                "All Users",
                "Dynamic Users"
            ],
            whatOptions: [],
            tabs: ["Configuration", "Analytics"],
            selectedtab: 0,
            widgetType: 'announcement',
            configuration: {
                "name": "",
                "description": "",
                "sections": [
                    {
                        "title": "WHAT",
                        fieldSaved: "widget",
                        desc: "Content that needs to be displayed",
                        "content": "",
                        "options": [],
                        draggable: true,
                        "type": "select"
                    },
                    {
                        "title": "WHERE",
                        desc: "App Pages where the widget needs to be displayed",
                        draggable: false,
                        "options": [
                            { "text": "Gmail", "description": "The Widget will be shown in all pages in gmail", "value": "mail.google.com", icon: "https://upload.wikimedia.org/wikipedia/commons/thumb/7/7e/Gmail_icon_%282020%29.svg/512px-Gmail_icon_%282020%29.svg.png" },
                            { "text": "Salesforce", "description": "The Widget will be shown in all pages in salesforce", "value": "appeq.my.salesforce.com", icon: "https://s2.googleusercontent.com/s2/favicons?domain_url=https://salesforce.com" },
                            { "text": "Hubspot", "description": "The Widget will be shown in all pages in hubspot", "value": "hubspot.com", icon: "https://s2.googleusercontent.com/s2/favicons?domain_url=https://hubspot.com" },
                            // { "text": "WEEKLY ONCE", "value": 1 }
                        ],
                        "content": "",
                        "type": "text"
                    },
                    {
                        "title": "WHEN",
                        draggable: true,
                        desc: "How many times it needs to be shown?",
                        fieldSaved: "frequency",
                        "options": [
                            { "text": "DAILY ONCE", description: "The widget will be shown daily once on the start of the day", "value": 1 },
                            { "text": "DAILY 3 TIMES", description: "The widget will be shown daily three times", "value": 3 },
                            { "text": "DAILY MULTIPLE TIMES", description: "The widget will be shown daily 10 times", "value": 10 },
                            { "text": "WEEKLY ONCE", description: "The widget will be shown weekly 1 time", "value": 1 },

                        ],
                        "selectedOption": "",
                        "type": "select"
                    },
                    {
                        draggable: true,
                        desc: "To whom this widget needs to be shown",
                        fieldSaved: "applicability",

                        "options": [
                            { "text": "All Users", description: "All the users using AppEQ", "value": "ALL_USERS" },
                            { "text": "Only CSMs", description: "All the CSM using AppEQ", "value": "CSM" },
                            { "text": "All Managers", description: "All the Manager using AppEQ", "value": "Manager" },
                            { "text": "CSM With Red accounts", description: "All the CSM with red accounts using AppEQ", "value": "weekly_once" }
                        ],
                        "title": "WHO",
                        "content": "",
                        "type": "select"
                    }
                ],
                "types": {
                    "tasklist": false,
                    "dashboard": true,
                    "accountSummary": false
                },
                "options": [
                    { "name": "Renewal Dashboard", "selected": false },
                    { "name": "Red Accounts Dashboard", "selected": false },
                    { "name": "NPS Dashboard", "selected": false },
                    { "name": "Executive Dashboard", "selected": false }
                ]
            },
            activeSection: null,
            users: [],
            showAccountfilter: false,
            linkopen: false,
            addStatus: false,
            newUrl: '',
            urlLen: '',
            showFilterModal: false,
            opertors: [
                { label: "Contains", value: "contains", query: "$regex" },
                { label: "Equal to", value: "==", query: "$eq" },

            ],
            nudgeData: {
                displayRules: {
                    WHAT: [],
                    WHERE: [],
                    WHEN: [],
                    WHO: [],
                    url: ""
                },
                content: {
                    title: "",
                    desc: "",
                    filterCond: []
                }
            },
            selectedAction: 0,
        }
    },
    methods: {
        removeConf(index){
            this.activeSection.options.splice(index,1)
        },
        openAdvancedSettings(){
                console.log("Advanced Conf")
                this.selectedAdvancedConf = true
        },
        removeSection(row){
            row['addedLeft']=false
            let updatedNudgeData = Object.assign({}, this.nudgeData);
                
                // Update the nested property dynamically
                updatedNudgeData[this.activeSection.fieldSaved] = null;

                // Assign the updated object back to nudgeData
                this.nudgeData = updatedNudgeData;
                this.$toasted.success("Successfully removed the selection")
        },
        async handleSaveConf(event) {
            this.selectedAdvancedConf = false;
            if(this.activeSection.title=='WHERE'){
                this.activeSection.options.splice(this.activeSection.options.length - 1, 0, {
                text: event.name,
                description: event.description,
                advanced: true,
                // advancedConf: event,
                value: event.url
            })
            }else
            this.activeSection.options.splice(this.activeSection.options.length - 1, 0, {
                text: event.name,
                description: event.description,
                advanced: true,
                advancedConf: event,
                value: {
                    advanced: true,
                    // advancedConf:event
                }
            })
            await Server.saveWidgetConf('update',{configuration:this.configuration})
        },
        attachLink() {
            this.linkopen = true
        },
        handleSectionSave(options) {
            console.log(options, "123")
           

                let updatedNudgeData = Object.assign({}, this.nudgeData);
                this.activeSection.options.map(d=>{
                    d.addedLeft=false
                })
                options['addedLeft']=true
                // Update the nested property dynamically
                updatedNudgeData[this.activeSection.fieldSaved] = options;
                console.log(options,this.activeSection, 'isko bhi dekh')

                // Assign the updated object back to nudgeData
                console.log(updatedNudgeData)
                this.nudgeData = updatedNudgeData;
                if(this.activeSection.title=='WHERE'){
                    this.nudgeData.displayRules.url = options.value;
                }
                console.log(this.nudgeData, 'yahi issue hai');
                this.$toasted.success("Successfully added the selection")
                return

            // Assign the updated object back to nudgeData
            // this.nudgeData.displayRules.url=options.value
            // console.log(this.nudgeData)/

        },
        onEnd(event) {
            // event.stop()
            if (event.type == 'end' && event.to != event.from) {
                let updatedNudgeData = Object.assign({}, this.nudgeData);

                // Update the nested property dynamically
                updatedNudgeData[this.activeSection.fieldSaved] = event.item._underlying_vm_;

                // Assign the updated object back to nudgeData
                this.nudgeData = updatedNudgeData;
                // this.nudgeData[this.activeSection.fieldSaved]=event.item._underlying_vm_;
                // this.nudgeData=Object.assign(this.nudgeData,...this.nudgeData)
                // this.$set(this.nudgeData,this.activeSection.fieldSaved,event.item._underlying_vm_)
            }
        },
        handleSaveFilter(event) {
            console.log("Handle save filter")
            console.log(event)
            this.nudgeData.content.filterCond.push(event);
            this.showFilterModal = false
        },
        async deleteWidget() {
            try {
                const response = await Server.deleteWidget(this.nudgeData);
                console.log(response);
                this.$emit('save', true);
            } catch (error) {

            }
        },
        setActiveSection(section) {
            this.activeSection = section;
            console.log(section, 'sectyion conose')
            if (section.title == 'WHAT') {
                if (this.widgetType == 'dashboard') {
                    this.activeSection.options = this.whatOptions.filter((opt) => {
                        return (opt.type == 'dashboard');
                    }).map(d => {
                        d.icon = "/img/onboarding2.png"
                        return d
                    })
                } else if (this.widgetType == 'tasklist') {
                    this.activeSection.options = this.whatOptions.filter((opt) => {
                        return (opt.type == 'tasklist');
                    }).map(d => {
                        d.icon = '/img/onboarding4.png'
                        return d
                    })
                } else if (this.widgetType == 'account summary') {
                    this.activeSection.options = this.whatOptions.filter((opt) => {
                        return (opt.type == 'accountsummary');
                    })
                } else if (this.widgetType == 'announcement') {
                    this.activeSection.options = this.whatOptions.filter((opt) => {
                        return (opt.type == 'announcement');
                    }).map(d => {
                        d.icon = "/img/announcment.png"
                        return d
                    })
                } else {
                    this.activeSection.options = this.whatOptions.filter((opt) => {
                        return (opt.type == this.widgetType);
                    })
                }
            }else if(section.title == 'WHEN' && this.conf){
                for(let i=0;i<this.activeSection.options.length;i++){
                    if(this.activeSection.options[i].text == this.conf.frequency.text){
                        this.activeSection.options[i]['addedLeft'] = true;
                    }
                }
            }else if(section.title == 'WHO' && this.conf){
                for(let i=0;i<this.activeSection.options.length;i++){
                    if(this.activeSection.options[i].text == this.conf.applicability.text){
                        this.activeSection.options[i]['addedLeft'] = true;
                    }
                }
            }else if(section.title == 'WHERE' && this.conf){
                console.log(this.conf)
                if(Array.isArray(this.conf.displayRules.url)){
                }
                for(let i=0;i<this.activeSection.options.length;i++){
                    if(Array.isArray(this.conf.displayRules.url)){
                    }else{
                        if(this.activeSection.options[i].value == this.conf.displayRules.url){
                            this.activeSection.options[i]['addedLeft'] = true;
                        }
                    }
                }
            }
        },
        async saveWidget() {
            try {
                if(this.nudgeData?.content?.title!='' && this.nudgeData?.widget?.addedLeft==true && this.nudgeData?.applicability?.addedLeft==true && this.nudgeData?.displayRules?.url!="" && this.nudgeData?.frequency?.addedLeft==true){
                    this.nudgeData.lastUpdatedAt = new Date()
                console.log(this.nudgeData, 'nudge data finally');
                const data = await Server.saveWidget(this.nudgeData)
                this.$toasted.success("Successfully Saved Widget")
                this.$emit('save', true);
                }else{
                    this.$toasted.error('Please fill all the fields');
                    return;
                }
            } catch (error) {
                this.$toasted.error(error)

            }
        },
        cancelWidget() {
            this.$emit('close');
        },
        handleAction(index) {
            this.selectedAction = index
        },

        async handleTab(idx) {
            this.selectedtab = idx
            if(idx==1){
                await this.openDash();
            }
            // this.logsNudges = (await Server.getNudgesLog(this.nudgeData.name)).data
        },
        async openDash(){
            var METABASE_SITE_URL = "https://metabase.appeq.ai";
            var METABASE_SECRET_KEY = "0775a85bd18796da29602a47df4625236ed1bb4250584697b91d7fbc4b27e779";
            const jwt = new JWT(METABASE_SECRET_KEY);
            let params = {widgetid: this.widgetId};
            const token = await jwt.sign({
                resource: { dashboard:305 },
                params: params,
                exp: Math.round(Date.now() / 1000) + (10 * 60)
            });
            var iframeUrl = METABASE_SITE_URL + "/embed/dashboard/" + token + "#theme=transparent&bordered=false&titled=false";
            this.iframeSrc = iframeUrl;
            this.loading=true;
            const iframe = document.getElementById("appeqIframe");
            if (iframe) {
                iframe.addEventListener("load", () => {
                    this.loading = false;
                    console.log("DATA ()90")
                });
                // Set the src after adding the event listener
                // iframe.src = iframeUrl;
            } else {
                this.loading = false;
            }
        },
        close() {
            this.$emit("close")
        },
        handleWidgetType() {
            console.log(this.activeSection, 'active section options');
            console.log(this.widgetType, 'type');
            // this.activeSection.options = this.whatOptions.filter((opt)=>{
            //         return (opt.type==this.widgetType);
            //     })
            this.setActiveSection(this.activeSection)
            console.log(this.activeSection);

        }
    }
}
</script>

<style lang="scss" scoped>
.nudge_configuration {
    position: fixed;
    right: -92vw;
    transition: right 1s ease-in-out;
    width: 92vw;
    top: 0px;
    height: 100vh;
    background-color: white;
    z-index: 999;
    border-radius: 20px 0px 0px 20px;
    padding: 13px 25px;
    box-shadow: rgba(0, 0, 0, 0.11) 0px -1px 1px, rgba(0, 0, 0, 0.11) 0px -2px 2px, rgba(0, 0, 0, 0.11) 0px -4px 4px, rgba(0, 0, 0, 0.11) 0px -6px 8px, rgba(0, 0, 0, 0.11) 0px -8px 16px;
    border: 1px solid rgb(236, 236, 236);

}

.tabs {
    display: flex;
    align-items: center;
}

.tab {
    margin-left: 10px;
}

.selectedTab {
    border-bottom: 2px solid blue;
    font-weight: 700;
}

.body {
    padding: 20px;
    display: flex;
    align-items: flex-start;
}

.nudgename {
    padding: 4px;
    outline: none;
    width: 100%;
    font-size: 12px;
    border: none;
    border-bottom: 1px solid #dbd7d7;
}

.nudgedesc {
    margin-top: 25px;
    width: 100%;
    font-size: 12px;

    outline: none;
    border: none;
    border-bottom: 1px solid #dbd7d7;
}

.leftBar {
    width: 40%;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-top: 10px;
}

.nudgesetup {
    margin-top: 25px;

    &__card {
        padding: 12px;
        border-radius: 6px;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        display: flex;
        align-items: center;
        cursor: pointer;
        margin-top: 20px;

        &__btn {
            border-radius: 8px;
            padding: 8px;
            font-weight: 500;
            background-color: rgb(236, 231, 255);
            margin-right: 20px;
        }

        &__empty {
            flex: 1;
        }
    }

    &__action {
        img {
            height: 20px;
        }
    }

}

.tabs {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #e8e8e8;
    margin-top: 5px;
    padding: 0px 10px;
    padding-right: 10px;

    :first-child {
        margin-left: 0px !important;
    }
}

.tab {
    color: black;
    margin-left: 40px;
    font-size: 16px;
    cursor: pointer;
    word-spacing: 2px;
}

.rightBar {
    padding: 14px;
    margin-left: 25px;
    width: 60%;
    height: 100%;
    border-radius: 12px;
    padding-bottom: 20px;
    // background-color: #4e5fff;
    background-color: rgb(236, 231, 255);

}

.actionBar {
    width: 100%;

    &__card {
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
        padding: 18px 15px;
        cursor: pointer;
        border-radius: 8px;
        margin-top: 20px;
        display: flex;
        align-items: center;
        width: 100%;

        &__heading {
            font-size: 18px;
            font-weight: 600;
            margin-bottom: 6px;
        }

        &__content {
            margin-left: 25px;
            flex: 1;
            padding-right: 12px;
        }
    }
}

.addBtn {
    font-size: 12px;
    font-weight: 700;
    color: blue;
    text-align: right;
    margin-top: 10px;
    cursor: pointer;
}

.personalfooter {
    padding: 20px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    position: absolute;
    bottom: 5px;
    right: 0px;

    &__btn {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex: 0.2;
        height: 25px;

        &--cancel {
            background-color: none !important;
            background: transparent !important;
            color: #4e5fff;
            border: 1px solid #4e5fff;
        }

        button {
            width: 45%;
            height: 35px;

            border-radius: 8px;
        }
    }

    img {
        height: 20px;
    }
}

.playBookBar {
    &__info {
        flex: 1;
    }

    &__heading {
        font-size: 18px;
        font-weight: 600;
        text-align: center;
    }

    &__desc {
        margin-top: 10px;
        margin-bottom: 10px;
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__btn {
        width: 20%;
        border: 1px solid #efefef;
        padding: 8px;
        display: flex;
        cursor: pointer;
        font-size: 12px;
        font-weight: 600;
        border-radius: 8px;
        align-items: center;

        input {
            margin-right: 10px;
        }
    }

    &__tasks {
        &__heading {
            font-size: 14px;
            margin-top: 20px;
            margin-bottom: 20px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &__title {
                font-weight: 700;

            }

            &__btn {
                border-radius: 8px;
                border: 1px solid blue;
                padding: 8px 12px;
                cursor: pointer;
                width: 15%;
                height: 30px;
                display: flex;
                align-items: center;
                justify-content: center;
                text-align: center;

                &:hover {
                    background-color: #4e5fff;
                    color: white;
                }
            }
        }
    }
}

.nudgesetp__card_selected {
    border: 1px solid #4e5fff !important;
}

.nudgesetup__card__desc {
    margin-right: 30px;
    display: n
}

.error {
    border-bottom: 1px solid red !important;
}

.delete {
    filter: invert(17%) sepia(99%) saturate(7275%) hue-rotate(1deg) brightness(102%) contrast(114%);
    height: 20px;
    margin-right: -4px;
    width: 21px;
    cursor: pointer;
}
</style>
<style>
#autosuggest__input {
    outline: none;
    color: #35495e;
    position: relative;
    display: block;
    font-family: monospace;
    font-size: 12px;
    border: 1px solid #e8e8e8;
    border-radius: 8px;
    padding: 10px;
    margin-left: 10px;
    margin-top: 10px;
    height: 45px;
    width: 245px;
    margin-left: 10px;
    box-sizing: border-box;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
}

#autosuggest__input.autosuggest__input-open {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
}

.autosuggest__results-container {
    position: relative;
    width: 100%;
}

.autosuggest__results {
    font-weight: 300;
    margin: 0;
    margin-left: 10px;
    position: absolute;
    z-index: 10000001;
    width: 100%;
    border: 1px solid #e8e8e8;
    border-bottom-left-radius: 4px;
    border-bottom-right-radius: 4px;
    background: white;
    padding: 12px;
    overflow: scroll;
    max-height: 200px;
}

.autosuggest__results ul {
    list-style: none;
    padding-left: 0;
    margin: 0;
}

.autosuggest__results .autosuggest__results_item {
    cursor: pointer;
    padding: 15px;
}

#autosuggest ul:nth-child(1)>.autosuggest__results_title {
    border-top: none;
}

.autosuggest__results .autosuggest__results_title {
    color: #adadad;
    font-size: 11px;
    margin-top: 10px;
    margin-left: 0;
    padding: 15px 13px 5px;
    border-top: 1px solid lightgray;
}

.autosuggest__results .autosuggest__results_item:active,
.autosuggest__results .autosuggest__results_item:hover,
.autosuggest__results .autosuggest__results_item:focus,
.autosuggest__results .autosuggest__results_item.autosuggest__results_item-highlighted {
    background-color: #ddd;
}

.tasksContent:hover {
    td:hover {
        background-color: #ffffff;

    }

    background-color: #f5f5f5;
}

.filerBox {
    padding: 8px 18px !important;
    width: 100% !important;
    height: 100% !important;
    font-size: 12px !important;
}

.logs-container {
    max-width: 600px;
    margin: 20px auto;
}

.log-item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    margin-bottom: 10px;
    /* background-color: #fff; */
    /* box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); */
}

.log-info {
    flex-grow: 1;
}

.log-message {
    font-weight: bold;
    margin-right: 10px;
}

input[type="text"] {
    border: none;
    outline: none;
    width: 100%;
    height: 38px;
}

.log-started-at {
    color: #666;
}

.log-status {
    padding: 5px 10px;
    border-radius: 5px;
    color: #fff;
    font-weight: bold;
}

.running {
    background-color: blue;
}

.success {
    background-color: #4caf50;
    /* Green */
}

.error {
    background-color: yellow;
    /* Red */
}

.whereCond {
    font-size: 16px;
    font-weight: 600;
}

.whereBlock {
    display: flex;
    align-items: center;
    margin-top: 20px;
}

.whoBloc {
    display: flex;
    align-items: center;
    margin-top: 20px;

}

.whenBloc {
    display: flex;
    align-items: center;
    margin-top: 20px;

}

.whereCond {
    margin-right: 10px;
    margin-left: 10px;
}

input[type="radio"] {
    margin-right: 10px;
}

.grid {
    display: flex;
    flex-direction: column;
}

.configuration-container {
    display: flex;
    justify-content: space-between;
}

.draggto {
    padding: 12px;
    border: 1px dotted black;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fbfbfb;
}

.section-block {
    cursor: pointer;
    display: flex;
    align-items: center;
    flex: 1;
    justify-content: space-between;
    margin-top: 40px;
}

.configuration-sidebar {
    flex: 0.55;
}

.configuration-detail {
    flex: 1;

}

.optioncard {
    display: flex;
    isolation: isolate;
    position: relative;
    width: 100%;
    height: 8rem;
    border-radius: 1rem;
    overflow: hidden;
    font-family: 'Gill Sans', 'Gill Sans MT', Calibri, 'Trebuchet MS', sans-serif;
    font-size: 16px;
    --gradient: linear-gradient(to bottom, #2EADFF, #3D83FF, #7E61FF);
    --color: #CECECE
}

.optioncard:before {
    position: absolute;
    content: "";
    inset: 0.0625rem;
    border-radius: 0.9375rem;
    box-shadow: 9px 9px 16px #CCCCCC, -9px -9px 16px #F4F4F4;
    background: #e0e0ff;
    z-index: 2
}

.optioncard:after {}

.optioncard:hover {
    border: 2px solid blue
}

.cardTitle {
    color: #756c6c;
    padding: 0.65rem 0.25rem 0.4rem 1.25rem;
    font-weight: 500;
    font-size: 1.1rem;
    transition: transform 300ms ease;
    z-index: 5;
    text-align: center
}
.tag-input__tag {
  display: inline-flex;
  align-items: center;
  background-color: #667eea;
  padding: 5px 8px;
  margin: 5px;
  border-radius: 5px;
  color: white;
  font-size: 15px;
}
.tag-input__tag span {
  cursor: pointer;
  margin-left: 5px;
}

.section-configuration {
    padding: 20px 30px;
}

.optioncard:hover .cardTitle {
    transform: translateX(0.15rem);
}

.cardDescription {
    color: #99999D;
    padding: 0 1.25rem;
    transition: transform 300ms ease;
    z-index: 5;
    font-size: 12px !important;
    text-align: center
}

.optioncard:hover .cardDescription {
    transform: translateX(0.25rem)
}

.notiglow,
.notiborderglow {
    position: absolute;
    width: 20rem;
    height: 20rem;
    transform: translate(-50%, -50%);
    background: radial-gradient(circle closest-side at center, blue, transparent);
    opacity: 0;
    transition: opacity 300ms ease;
}

.notiglow {
    z-index: 3;
}

.notiborderglow {
    z-index: 1;
}

.optioncard:hover .notiglow {
    opacity: 0.1
}

.optioncard:hover .notiborderglow {
    opacity: 0.1
}

.selectedAction {
    border: 1px solid #9090ff;
    padding: 8px;
    background: aliceblue;
    border-radius: 10px;
}

.configuration-separator {
    flex: 0.1;
}
</style>
<style lang="scss">
iframe {
    &.ql-video {
        height: 100% !important;

    }
}

.ql-editor .ql-video {
    height: 100%
}

td {
    padding: 10px
}
.alignEnd{
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 1vh;
}
</style>