<template>
    <div class="wrapper-div">

        <div class="summary__mainrow">
            <span class="summary__heading">Choose a template</span>          
        </div>
        <div class="text-span">
            <i class="tim-icons icon-alert-circle-exc"></i>
            <span >Please note that these are just templates it does not
                represent actual
                data
            </span>
        </div>
        <div class="summary">
            
            <div class="summary__card">
                <div class="summary__card--heading">
                    <img src="https://s2.googleusercontent.com/s2/favicons?domain_url=https://tesla.com&sz=128" />
                    Tesla
                </div>
                <div class="summary__card--body">
                    <div class="summary__card--mainRow">
                        <div>
                            <div class="summary__card--row">
                                <span class="summary__card--boldtcv"> Name</span> : Lorem
                            </div>
                            <div class="summary__card--row">
                                <span class="summary__card--boldtcv"> ARR </span>: $135.4M
                            </div>
                            <div class="summary__card--row">
                                <span class="summary__card--boldtcv">Start Date </span>: 12 Feb 2023(in 2 months)
                            </div>
                            <div class="summary__card--row">
                                <span class="summary__card--boldtcv">Renewal Date </span>: 12 April 2023(in 2 months)
                            </div>
                            <div class="summary__card--row">
                                <span class="summary__card--boldtcv"> Health </span>: <div class="health">RED</div>
                            </div>

                        </div>
                        <div style="margin-left:25px">
                            <div class="summary__card--row">
                                <span class="summary__card--boldtcv "> Country </span> : Australia
                            </div>
                            <div class="summary__card--row">
                                <span class="summary__card--boldtcv"> Geo </span> : 4 Opps ($424K)
                            </div>
                            <div class="summary__card--row">
                                <span class="summary__card--boldtcv"> Vertical</span>: 2($45000)
                            </div>
                            <div class="summary__card--row">
                                <span class="summary__card--boldtcv"> Accounting Type </span>: 4 Days ago
                            </div>
                            <div class="summary__card--row">
                                <span class="summary__card--boldtcv"> Product Type </span>: 4 Days ago
                            </div>
                        </div>
                        <div style="margin-left:25px">
                            <div class="summary__card--row">
                                <span class="summary__card--boldtcv"> Invoice Number</span> : 124322212
                            </div>
                            <div class="summary__card--row">
                                <span class="summary__card--boldtcv"> Due Date</span> :  12 Feb 2023
                            </div>
                            <div class="summary__card--row">
                                <span class="summary__card--boldtcv"> Amount </span>: 2($45000)
                            </div>
                            <div class="summary__card--row">
                                <span class="summary__card--boldtcv"> Status </span>: 4 Days ago
                            </div>
                            <div class="summary__card--row">
                                <span class="summary__card--boldtcv"> Dso </span>: 4 Days ago
                            </div>
                        </div>
                    </div>
                </div>
                <div class="summary__card--footer">
                    <img class="small_logo" src="https://www.appeq.ai/images/logo/appEQ-squareLogo-new.png"></img>
                    Powered by AppEQ.ai
                </div>
                <button v-tooltip.bottom-start="'Click to Configure your Summary Widget'" class="nextButton" @click="handleNextDesign()">Next - <i class="tim-icons icon-minimal-right"></i></button>
         </div>
    </div>
    </div>
</template>

<script>
import mixpanel from '../../analytics/mixpanel';

export default {
    methods: {
        handleNextDesign() {
            this.$router.push("template-designer")
        }
    },
    mounted(){
        console.log("visited template");
        try{
            mixpanel.visitedSummaryTemplate();
            this.$router.push("template-designer")

        }catch(e){
            console.log(e);
        }
    }
}
</script>

<style lang="scss" scoped>

@import '../../assets/sass/black-dashboard/custom/themes';

.small_logo{
    height: 20px;
    margin-bottom: auto;
}

.wrapper-div{
    min-width:600px ;
}

.text-span{
        margin-left:15px;
        margin-bottom:30px;
        height:40px;
        background: rgb(255, 249, 230);
        color: rgb(99, 99, 99);
        display: flex;
        -webkit-box-align: center;
        align-items: center;
        border-radius:10px ;
        i{
            margin-right: 10px;
            margin-left: 5px;
            color: rgb(202, 143, 0);
        }

    }
.summary {
    width: 1000px;
    &__heading {
        margin-top: 10px;
        font-size: 16px;
        padding-left: 20px;
        font-weight: 700;
        color:$login-button-color;
    }

    &__card {
        width: 75%;
        margin-top: 25px;
        border: 0.5px solid red;
        background-color: white;
        border-radius: 20px;
        margin-top: 20px;
        padding: 15px 11px;
        box-shadow: 0px 0px 5px #c8c8c8;

        margin-left: 20px;

        &--footer {
            font-size: 10px;
            margin-bottom: -10px;
            margin-top: 10px;
            display: flex;
            align-items: center;
            justify-content: flex-end;
        }

        &--boldtcv {
            font-weight: 600;
            font-style: italic;
        }

        &--boldtcv.heading {
            font-weight: 700;
            font-style: normal;
            font-size: 14px;
        }

        &--mainRow {
            display: flex;
        }

        &--row {
            margin-top: 8px;
            display: flex;
        }

        &--heading {
            display: flex;
            color: blue;
            font-weight: 600;
            border-bottom: 0.5px solid rgba(196, 196, 196, 0.74);
            padding-bottom: 10px;

            img {
                height: 28px;
                margin-right: 10px;
            }
        }
    }

    &__input {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 12px;
    }

    &__mainrow {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;
    }
}

.health {
    padding: 2px 20px;
    color: white;
    background-color: red;
    border-radius: 4px;
    margin-left: 4px;
}

.nextButton {
    position: absolute;
    right:120px;
    border: 1px solid $login-button-color;
    color: $login-button-color;
    height: 30px;
    margin-top: 20px;
    cursor: pointer;
    width: 130px;
    border-radius: 10px;
    background: none;
    transition: 0.2s ease-in-out;
    display: flex;
    align-items: center;
    justify-content: center;

    &:hover{
        background: blue;
        color: white;
        transition: 0.2s ease-in-out;
    }

    .tim-icons{
        font-size: 12px;
    }
}
</style>