<template>
    <div v-if="page==0 && !loading">
        <div style="height: 30vh;">
            <span style="font-size: 16px;font-weight: 800; margin-top: 10px;">Business Objects to Sync</span>
            <input placeholder="Enter business object name here"  style="border: 1px solid gainsboro; border-radius: 6px; outline: none; width: 100%; font-size: 12px; height: 30px;" type="text" v-model="selectedBObject">
        </div>
        <div style="text-align: center;">
            <button @click="gotoPage1" style="outline: none; border-radius: 7px;">Next</button>
        </div>
    </div>
    <div v-else-if="page == 1 && !loading">
        <h3 style="text-align: center;">Upload Excel Here</h3>
        <div @click="triggerFileInput" style="display: flex; flex-direction: column; align-items: center; justify-content: center; border: 1px solid gainsboro; border-radius: 7px; height: 18vh; padding: 10px; cursor: pointer;">
            <img src="../icons/upload.png" style="height: 4vh; cursor: pointer;" alt="Google Sheets Logo">
        </div>
        <input type="file" ref="fileInput" @change="handleFileUpload" style="display: none;" accept=".xlsx"/>
    </div>
    <div v-else-if="page==2 && !loading">
        <div style="height: 30vh;">
            <multiselect :append-new-option="true"  :create-tag="true"  :searchable="true"  :hide-selected="true" select-label="" openDirection="below" :taggable="true" @tag="addTag($event, index)" :multiple="true"
        :options="excelSheetOptions"  v-model="sheetSelected"  placeholder="Select Sheets From Here"></multiselect>
        </div>
        <div style="text-align: center;">
            <button @click="handleSelectColumns" style="outline: none; border-radius: 7px;">Next</button>
        </div>
    </div>
    <div v-else-if="page==3 && !loading">
        <div style="height: 30vh; overflow: scroll;">
            <div v-for="(item) in sheetData" style="padding: 5px; border: 1px solid #b7b7b7; border-radius: 6px; margin-bottom: 10px;">
                <div style="  width: 50%; color: black; border-radius: 4px; padding-left: 4px; margin-bottom: 5px;">
                    {{ item.sheetName }}
                </div>
                <div style="display: flex; flex-direction: column; margin-bottom: 1vh;">
                    <label for="">
                        Header Number
                    </label>
                    <input placeholder="Enter Header Number (Top header starts from 0th index)"  style="border: 1px solid gainsboro; border-radius: 6px; outline: none; width: 95%; font-size: 9px; height: 30px;" type="number" v-model="item.headerNum">
                </div>
                <div style="display: flex; flex-direction: column; margin-bottom: 1vh;">
                    <label for="">
                        Domain Key
                    </label>
                    <input  placeholder="Enter Domain Key Column Name" style="border: 1px solid gainsboro; border-radius: 6px; outline: none; width: 95%; font-size: 9px; height: 30px;" type="text" v-model="item.domainKey">
                </div>
                <div style="display: flex; flex-direction: column; margin-bottom: 1vh;">
                    <label for="">
                        Domain Id
                    </label>
                    <input  placeholder="Enter Domain Id Column Name" style="border: 1px solid gainsboro; border-radius: 6px; outline: none; width: 95%; font-size: 9px; height: 30px;" type="text" v-model="item.domainId">
                </div>
            </div>
        </div>
        <div style="text-align: center;">
            <button @click="handleSave" style="outline: none; border-radius: 7px;">Save</button>
        </div>
    </div>
    <div v-else-if="page==4 && !loading">
        <h5 class="successText">
          You have successfully uploaded the sheet.
        </h5>
        <img src="../assets/success.svg" />
    </div>
    <div v-else style="display: flex; align-items: center; justify-content: center;">
        <img src="../icons/loadingripple.svg" style="height: 100px;" alt="">
    </div>
</template>

<script>
import Multiselect from 'vue-multiselect';
import Server from './API/Server';

export default {
    data(){
        return {
            excelSheetOptions: [],
            sheetSelected: [],
            availFile: null,
            selectedSheetIdx: -1,
            page: 0,
            sheetData: [],
            selectedCol: null,
            loading: false,
            domain: null,
            key: null,
            selectedBObject: null
        }
    },
    props: {
        item: {}
    },
    methods: {
        triggerFileInput() {
            this.$refs.fileInput.click();
        },
        gotoPage1(){
            if(!this.selectedBObject){
                this.$toasted.error('Please enter a business object');
                return;
            }
            this.page += 1;
        },
        async handleFileUpload(event) {
            const file = event.target.files[0];
            if (file) {
                this.loading = true;
                this.availFile = file;
                let sheets = await Server.getExcelSheetNames(file);
                this.excelSheetOptions = sheets.data;
                this.page+=1;
                this.loading = false;
            }
        },
        handleSelectColumns(){
            for(let i=0;i<this.sheetSelected.length;i++){
                this.sheetData.push({'sheetName':this.sheetSelected[i], 'headerNum':null, 'domainKey':null, 'domainId': null});
            }
            this.page += 1;
        },
        async handleSave(){
            console.log(this.sheetData, 'sheet data');
            for(let i=0;i<this.sheetData.length;i++){
                if(!this.sheetData[i]['headerNum'] || !this.sheetData[i]['domainKey'] || !this.sheetData[i]['domainId']){
                    this.$toasted.error("Please configure all columns keys before saving");
                    return;
                }
            }
            this.loading = true;
            try {
                let resp = await Server.uploadExcelData(this.sheetData, this.availFile, 'appeqUpload', this.selectedBObject);
                console.log(resp);
                if(resp.data.code !==200){
                    this.$toasted.error('Some error occured');
                    return;
                }
                this.page+=1;
                this.loading = false;
                setTimeout(() => {
                    window.location.reload();
                }, 2000);
            } catch (error) {
                this.loading = false;
                console.log(error);
            }
        },
    },
    components: {
        Multiselect
    }
}
</script>
