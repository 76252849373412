<template>
  <div class="custom-recurrence">
    <h2 style="text-align: center;">Custom Recurrence</h2>
    <input class="intervalInput" style="width: 25%;" placeholder="Enter name for your configuration" v-model="recurrence.name"/>
    <input class="intervalInput" style="margin-top: 20px;margin-bottom: 20px;" placeholder="Enter description for your configuration" v-model="recurrence.description"/>
    <div style="display: flex;align-items: center">
      <label>Repeat every</label>
      <input class="intervalInput" type="number" min="1" v-model="recurrence.interval" />
      <Multiselect v-model="recurrence.unit" style="width: 20% !important;margin-left: 10px;" :options="['days','weeks','months']" />
    </div>
    <div v-if="recurrence.unit === 'weeks'">
      <label>Repeat on</label>
      <div class="days-of-week">
        <span style="display: flex;align-items: center;" v-for="day in daysOfWeek" :key="day.short">
          <input type="checkbox" :id="day.short" :value="day.short" v-model="recurrence.days">
          <label style="margin-left: 10px;margin-bottom: 0px;" :for="day.short">{{ day.full }}</label>
        </span>
      </div>
    </div>

    <div>
      <label>Ends</label>
      <div>
        <input type="radio" id="never" value="never" v-model="recurrence.ends.type" />
        <label for="never">Never</label>
      </div>
      <div>
        <input type="radio" id="onDate" value="onDate" v-model="recurrence.ends.type" />
        <label for="onDate">On</label>
        <input type="date" v-model="recurrence.ends.onDate" :disabled="recurrence.ends.type !== 'onDate'"/>
      </div>
      <div>
        <input type="radio" id="after" value="after" v-model="recurrence.ends.type" />
        <label for="after">After</label>
        <input type="number" min="1" v-model="recurrence.ends.afterOccurrences" :disabled="recurrence.ends.type !== 'after'" /> occurrences
      </div>
    </div>
      <div class="btnClass">
        <button @click="submitRecurrence">Save</button>

      </div>
  </div>
</template>

<script>
import Multiselect from 'vue-multiselect';

export default {
  components:{
    Multiselect
  },
  data() {
    return {
      recurrence: {
        interval: 2,
        unit: 'weeks',
        days: [],
        ends: {
          type: 'never',
          onDate: '',
          afterOccurrences: 1
        }
      },
      daysOfWeek: [
        { short: 'M', full: 'Monday' },
        { short: 'T', full: 'Tuesday' },
        { short: 'W', full: 'Wednesday' },
        { short: 'T', full: 'Thursday' },
        { short: 'F', full: 'Friday' },
      ]
    };
  },
  methods: {
    submitRecurrence() {
      console.log('Recurrence settings:', this.recurrence);
      this.$emit("save",this.recurrence)
      // alert('Recurrence settings have been saved. Check console for details.');
    }
  }
};
</script>

<style>
.custom-recurrence {
  display: flex;
  flex-direction: column;
  padding: 20px;
  border-radius: 4px;
}
.custom-recurrence div {
  margin-bottom: 10px;
}
.custom-recurrence label {
  margin-right: 10px;
}
input[type="number"], input[type="date"], select {
  margin-left: 10px;
}
button {
  margin-top: 20px;
}
.intervalInput{
  border: none;
  outline: none;
  border-bottom: 1px solid #efefef;
}
.btnClass{
  position: absolute;
  right: 10px;
  bottom: 10px;
  button{
    width: 120px;
    height: 35px;
  }
}
.days-of-week{
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}
</style>
