<template >
    <div>
        <img class="loading" v-if="loading" src="../assets/loadingRipple.svg" />
        <div v-if="showBusinessObjectPage && (selectedItem.productName!='Upload' && selectedItem.productName!='appeqUpload')">
            <span id="data_source_business_objec_applist" style="font-size: 16px;font-weight: 800;">{{selectedItem.productName=='API Ingestion'?'Write':'Choose'}} the Business Objects to Sync</span>
            <template v-if="selectedItem.productName == 'Google Sheet' || selectedItem.productName == 'spreadsheet'">
                <Multiselect id="mySelect1" @tag="addTag($event, index)"   select-label="" class="multiStyle" :taggable="true" :hide-selected="true" :options="business_object" v-model="selectedBObjecte"></Multiselect>
                <span style="margin-top: 5px; margin-bottom: 30px;" class="textShow">You can select one buisness object for Google
                    Sheet</span>
            </template>
            <template v-if="selectedItem.productName == 'API Ingestion'">
                <Multiselect id="mySelect1" @tag="addTag($event, index)"   select-label="" class="multiStyle" :taggable="true" :hide-selected="true" :options="business_object" v-model="selectedBObjecte"></Multiselect>
                <span v-if="!selectedBObjecte.length == 0" class="textShow">You can write your custom business object for Api</span>
            </template>
            <template v-else>
                <Multiselect id="mySelect2" class="multiStyle" style="height: 200px;overflow: scroll;"
                :multiple="true" select-label="" :taggable="true" :hide-selected="true" :options="business_object" v-model="selectedBObjecte">
                </Multiselect>
                <span v-if="!selectedBObjecte.length == 0" class="textShow">You can select multiple buisness objects</span>
            </template>
            <span v-if="business_object.length == 0 && selectedItem.productName!='API Ingestion'" class="error12">Supported Buisness Object for this appliation is already
                connected . Please unlink first and then connect</span>
            <button :class="selectedBObjecte.length > 0 ? '' : 'disabled'" @click="goToNextPage()"
                style="margin-top: 50px;">Next</button>
        </div>
        <div v-if="selectedItem.productName=='Upload' || selectedItem.productName=='appeqUpload'">
            <UploadSheetModal :item="selectedItem" />
        </div>
        <!-- <input v-if="!showDescPage && !loading && !showBusinessObjectPage"  @change="(v)=>onchange(v)" placeholder="Search apps" style="width: 100%;
    margin-bottom: 20px;
    border: none;
    border-bottom: 1px solid rgb(0,0,0,0.1);"/> -->
        <div v-if="!showDescPage && !loading && !showBusinessObjectPage" style="">
            <h4 style="text-align: center;">Select Apps</h4>
            <div class="card title" v-for="app in appsList">
                {{ app.key }}
                <div style="display: flex;align-items: center;margin-top: 10px;">
                    <div @click="handleClick(item)" :class="item.disabled ? 'disabled' : ''" style="display: flex;flex-direction: column;margin-left: 20px;
                        align-items: center;justify-content: center;" v-for="item of app.items">
                        <img style="height: 35px;width: 35px;" class="imageUrl" :src="item.logoURL" />
                        <div style="font-size: 12px;font-weight: 400;" class="enabled">
                            {{ item.productName.toString().split(" ")[0] }}
                        </div>

                    </div>
                </div>

            </div>
        </div>
        <DescPageVue v-if="showDescPage" :imageUrl="selectedItem"></DescPageVue>

    </div>
</template>
<script>
import Server from '../components/API/Server';
import Multiselect from 'vue-multiselect';
import DescPageVue from '../components/DescPage.vue';
import UploadSheetModal from "../components/UploadSheetModal.vue";
export default {
    name: "Apps",
    components: {
        DescPageVue,
        UploadSheetModal,
        Multiselect
    },
    props:[
        "openFromNew",
        "selectedProp"
    ],
    data() {
        return {
            appsList: [],
            showBusinessObjectPage: false,
            business_object: [],
            loading: false,
            showDescPage: false,
            selectedBObjecte: [],
            mainAppLits: [],
            selectedItem: null
        }
    },
    methods: {
        addTag(newTag, index) {
            const parts = newTag.split(', ');

            const tag = newTag;
            this.business_object.push(tag);
            this.selectedBObjecte.push(tag)
        },
        async goToNextPage() {
            console.log(this.selectedItem)
            if(this.selectedBObjecte == '' || this.selectedBObjecte.length==0){
                this.$toasted.error("Please Select a Business Object First");
                return
            }
            if(this.selectedItem.productName=="API Ingestion"){
                const data=await Server.getTokenForIngestion(this.selectedBObjecte[0])
                console.log(data)
                this.selectedItem={...this.selectedItem,token:data.token}
            }

            localStorage.setItem("Business_obj_name", this.selectedBObjecte)
            console.log(this.selectedItem)
            this.showBusinessObjectPage = false;
            this.showDescPage = true
        },

        handleClick(item) {
            console.log(item, 'code here to get item');
            this.showBusinessObjectPage = true
            this.selectedItem = item;
            this.business_object = item.business_object_name
            var creds = JSON.parse(localStorage.getItem("ALL_CREDS"))
            let newArr = []
            if(this.business_object)
            this.business_object.map((data, index) => {
                console.log(data)
                if (creds.filter(d => d.business_object_name.toLowerCase() == data.toLowerCase()).length == 0) {
                    console.log("ADA")
                    newArr.push(data)
                } 

            })
            console.log(newArr, 'newArr herre')
            // this.business_object = newArr
            const nArr=[]
            console.log(this.selectedItem.productName)
            if(this.selectedItem.productName.toLowerCase()=="salesforce" || this.selectedItem.productName.toLowerCase()=="zoho crm"){
                console.log("asjkdhj")
                creds.map(data=>{
                    if(data["appeq_supported_application"]=="salesforce" || data['appeq_supported_application']=='zoho crm'){
                        if(data["custom_objects"] && Array.isArray(data["custom_objects"] )){
                            data["custom_objects"].map(d=>{
                                if(creds.filter(cred => cred.business_object_name == d).length > 0){
                                    return
                                }
                                nArr.push(d)
                            })
                            return
                        }

                    }
                })
               
            }
            if(this.selectedItem.productName != 'Google Sheet'||this.selectedItem.productName=="spreadsheet" || this.selectedItem.productName=="API Ingestion")
                this.selectedBObjecte = newArr
            if(this.selectedItem.productName=="API Ingestion"){
                this.business_object.push("Product Usage")
            }
            this.business_object=[...nArr,...this.business_object]
            console.log(this.selectedItem)
            console.log(this.selectedBObjecte)

        },
        onchange(v) {
            if (v.target.value)
                Object.keys(this.appsList).map(data => {
                    this.mainAppLits[data].items.map(d => {
                        if (d.productName.toLowerCase().includes(v.target.value)) {
                            console.log(this.mainAppLits[data].items)
                            this.appsList = {}
                            this.appsList[data] = { key: data, items: this.mainAppLits[data].items }
                        }
                    })
                })
            else
                this.appsList = this.mainAppLits
            console.log(this.appsList);
        }

    },
    async mounted() {
        this.loading = true
        this.showDescPage = false;
        const data = await Server.getApps()
        this.appsList = data.data
        console.log(this.appsList, "here")
        let testArr = {};
        var creds = JSON.parse(localStorage.getItem("ALL_CREDS"))
        //mapping the appdatas to category wise items so that its easier to display in UI
        this.appsList.map((data) => {

            if (testArr[data.apps.g2Category]) {
                let disavled = false
                //     if(creds.filter(cred=>cred.business_object_name==data.apps.productName).length!=0){
                //     disavled=true   
                // }
                testArr[data.apps.g2Category]["items"].push({ ...data.apps, tooltip: false, disabled: disavled });
            } else {
                let disavled = false
                console.log(data.apps.productName)
                // if(creds.filter(cred=>data.apps.productName.toLowerCase().includes(cred.appeq_supported_application.toLowerCase())).length!=0){
                //     disavled=true   

                // }
                testArr[data.apps.g2Category] = {
                    items: [{ ...data.apps, tooltip: false, disabled: disavled }],
                    key: data.apps.g2Category,
                };
            }

        });
        testArr["API Ingestion"]={
            items:[{logoURL:"https://app.appeq.ai/img/appeq.cc5b93eb.png",productName:"API Ingestion",disabled:false}],
            key:"API Ingestion"
        }

        this.mainAppLits = testArr
        this.appsList = testArr;
        console.log(testArr)
        this.loading = false
        if(this.openFromNew){
            this.handleClick(this.selectedProp)
        }
    }
}
</script>
<style lang="scss" >
.title {
    padding: 7px 12px;
    cursor: pointer;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
}

input {
    outline: none;

    &:active {
        border: none;
    }

    &:focus {
        border: none;
    }
}

.loading {
    display: flex;
    align-items: center;
    justify-self: center;
    width: 100%;
}

.modal-search .modal-dialog span {
    font-size: 12px;
    text-transform: capitalize;
}

.multiselect {
    max-width: 310px;
}

.disabled {
    opacity: 0.3;
    pointer-events: none;
}

.error12{
    color: red !important;
    width: 310px !important;
    display: block;
    margin-top: 20px;
    text-align: center;
    text-transform: none !important;
    font-size: 12px !important;
}

.textShow {
    display: flex;
    align-items: center;
    justify-content: center;
    color: rgb(0, 0, 0) !important;
    margin-top: 20px;
}

#searchModal > div > div > div.modal-body > div > div > div > div.multiselect__tags > div.multiselect__tags-wrap > span > span{
    color:white !important;
    font-size: 15px !important;
}


.multiStyle .multiselect__option--selected.multiselect__option--highlight 
{
    span{
        font-size: 15px;
        color: white;
    }
    &::after{
        color: white;
        font-weight: 500;
    }
}
.multiStyle .multiselect__option--highlight 
{
    span{
        font-size: 15px;
        color: white;
        font-weight: 550;
    }
    &::after{
        color: white;
        font-weight: 500;
        width: auto;
    }
}
.multiStyle .multiselect__input, .multiselect__single{
   
        color: black !important;
        font-size: 15px !important;
    
}
.multiStyle{
    margin: auto;
}

</style>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
