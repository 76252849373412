<template>
    <div style="padding: 12px 20px;" class="card">
        <div class="widget__header">
            <img class="widget__header__img" src="../icons/widgetTrigger.png" />
            <div style="width: 60%;margin-left: 20px;" class="">
                <h3 class="widget__header__title">Launcher Triggers</h3>
                <span class="widget__header__desc">Activate widgets by adding new triggers on top of any enterprise web applications to give customer insights at right time and right place</span>
            </div>
        </div>
        <div style="display: flex;align-items: center;justify-content: space-between;margin-top: 20px; width: 100.5%;" class="">
            <GlobalTabs @selectTab="changeTab($event)" :notIdx="false" :tabs="allTabs" />
            <div style="display: flex;align-items: center;justify-content: flex-start;" class="">
                    <AddButtonGlobal @button="selectedWidgetConf=null;showConf=!showConf"/>
            </div>

        </div>
        <GlobalTable @handleAction="handleAction($event)" v-if="!loading && widgetsToDispay.length>0" :columns="columns" :tableData="widgetsToDispay" />
            <div v-else-if="!loading && widgetsToDispay.length==0" style="display: flex; align-items: center; justify-content: center; height: 69vh;">
                <img src="../icons/noData.svg" style="height: 18vh;" alt="">
            </div>
        
        <div v-else style="display: flex; justify-content: center; align-items: center; height: 68vh; overflow: scroll;">
            <img src="../icons/loadingripple.svg" style="height: 200px;" alt="">
        </div>
        <WidgetConfiguration :conf="selectedWidgetConf" @save="handleSavedNow($event)" @close="showConf=false" v-if="showConf"/>
    </div>

</template>
<script>
import Server from "../components/API/Server";
import moment from "moment";
import WidgetConfiguration from "../components/WidgetConfiguration.vue"
import AddButtonGlobal from "../layout/dashboard/AddButtonGlobal.vue";
import LocalUtils from "../components/Utils/LocalUtils";
import GlobalTabs from "../layout/dashboard/GlobalTabs.vue";
import GlobalTable from "../layout/dashboard/GlobalTable.vue";
export default {
    data() {
        return {
            showConf:false,
            selectedWidgetConf:null,
            selectedTabIdx:0,
            loading: false,
            allTabs: ['Smart Popups', 'On Page Launchers'],
            widgetsToDispay:[],
            columns:[
                {title:"Name",key:"content.title",width:"14%"},
                // {title:"Description",key:"customMsg",width:"27%"},
                {title:"What",key:"widget.name",width:"22%"},
                // {title:"Type",key:"type",width:"10%"},
                {title:"Where",key:"whereURL",width:"14%"},
                {title:"When",key:"frequency.text",width:"14%"},
                {title:"Who",key:"applicability.text",width:"12%"},
                {title:"Last Modified",key:"updatedAt",width:"14%"},
                {title:"Action",key:"action",width:"20%"}


            ],
            widgets:[]
        }
    },
    methods:{
        openUrl(url){
            window.open(url)
        },
        getWidgetImage(type){
            switch(type.toLowerCase()){
                case 'annoucment':return '/img/announcment.png';
                case 'tasklist':return '/img/onboarding4.png'
                case 'dashboard': return '/img/onboarding2.png'
                case 'data object list':return '/img/onboarding1.png';
                default: return '/img/announcment.png'
            }
        },
        handleOpenWidgetConf(conf){
            if(this.selectedTabIdx==1){
                try {
                // const wid=window.open("https://"+conf.displayRules.url)
                const email=LocalUtils.getEmail()
                window.postMessage({"action":"__appeq_nudge_widget_from_chrome","data":{url:"https://"+(Array.isArray(conf.displayRules.url) ? conf.displayRules.url[0].url : conf.displayRules.url),"email":email,widget:[conf]}},"*")
                } catch (error) {
                    console.log(error)
                }
                return
            }
            
            this.selectedWidgetConf=conf
            this.showConf=true;
        },
        encodeUnicodeBase64(str) {
            const uint8Array = new TextEncoder().encode(str);
            let binaryString = '';
            for (let i = 0; i < uint8Array.length; i++) {
                binaryString += String.fromCharCode(uint8Array[i]);
            }
            const base64String = btoa(binaryString);
            // Make the base64 string URL-safe
            return base64String.replace(/\+/g, '-').replace(/\//g, '_').replace(/=+$/, '');
        },
        handleOpenWidgetPreview(widget) {
            console.log(widget);
            const jsonString = JSON.stringify(widget);
            const base64Token = this.encodeUnicodeBase64(jsonString);
            console.log(LocalUtils.getEmail());
            window.open('https://' + (Array.isArray(widget.displayRules.url) ? widget.displayRules.url[0].url : widget.displayRules.url) + `?email=${LocalUtils.getEmail()}&token=${base64Token}`);
        },
        async handleDelete(widget, idx){
            let res = await Server.deleteWidget(widget);
            console.log(res);
            this.widgetsToDispay.splice(idx, 1);
        },
        getHumanDate(date) {
            return moment(date).format("DD MMM")

        },
        changeTab(idx){
            this.loading = true;
            this.selectedTabIdx=idx;
            console.log(this.widgets)
            if(this.selectedTabIdx==0){
                this.widgetsToDispay=[];

                //{action:"openEye",img:"",hover:""}
                for(let i=0;i<this.widgets.length;i++){
                    if(this.widgets[i].type!='manual'){
                        this.widgetsToDispay.push(
                            {...this.widgets[i], 'whereURL': this.getAllUrls(this.widgets[i]),'updatedAt': this.getHumanDate(this.widgets[i].updatedAt), 'action': [{'img':'View.png', 'action': 'openPreview', 'hover': 'Preview'}, {'img':'ConfigureIcon.png', 'action': 'openConfigure', 'hover': 'Configure'}]}
                        )
                    }
                }

            }else{
                this.widgetsToDispay=[]
                for(let i=0;i<this.widgets.length;i++){
                    if(this.widgets[i].type=='manual'){
                        this.widgetsToDispay.push(
                            {...this.widgets[i], 'whereURL': this.getAllUrls(this.widgets[i]), 'updatedAt': this.getHumanDate(this.widgets[i].updatedAt), 'action': [{'img':'View.png', 'action': 'openPreview', 'hover': 'Preview'}]}
                        )
                    }
                }

            }
            console.log(this.widgetsToDispay)
            this.loading = false;
        },
        handleAction(action){
            switch (action[0]) {
                case 'openPreview':
                        this.handleOpenWidgetPreview(action[1]);
                    break;
                case 'openConfigure':
                        this.handleOpenWidgetConf(action[1]);
                    break;
                default:
                    break;
            }
        },
        getAllUrls(data){
            if(Array.isArray(data.displayRules.url)){
                let str = '';
                for(let i=0;i<data.displayRules.url.length;i++){
                    if(data.displayRules.url[i].operetor=='Contains'){
                        str += '(Contains) ';
                        str += data.displayRules.url[i].url;
                    }else{
                        str += '(= Equals) ';
                        str += data.displayRules.url[i].url;
                    }
                    if(i != data.displayRules.url.length-1){
                        str += ', ';
                    }
                }
                return str;
            }else{
                return data.displayRules.url;
            }
        },
        async initData(){
            try {
                this.widgetsToDispay = [];
                this.loading=true;
            const data=await Server.getWidgets()
            if(data.code==200){
                this.widgets=data.data
                this.widgets=this.widgets.map(d=>{
                    // d.urlFilter=`URL ${d.displayRules.operator.label} ${d.displayRules.url}`
                    if(d.type=='manual'){
                        d={
                            ...d,
                            frequency:{
                                text:"On Click"
                            }
                        }
                    }
                    return d
                })
                for(let i=0;i<this.widgets.length;i++){
                    if(this.widgets[i].type!='manual'){
                        this.widgetsToDispay.push(
                            {...this.widgets[i], 'whereURL': this.getAllUrls(this.widgets[i]),'updatedAt': this.getHumanDate(this.widgets[i].updatedAt), 'action': [{'img':'View.png', 'action': 'openPreview', 'hover': 'Preview'}, {'img':'ConfigureIcon.png', 'action': 'openConfigure', 'hover': 'Configure'}]}
                        )
                    }
                }
                console.log(this.widgetsToDispay)
            }else{
                this.loading =  false;
                // this.$toasted.error(data.error)
            }
            this.loading =  false;

        } catch (error) {
            this.loading = false;
            this.$toasted.error(error)    
            }
        },
        handleSavedNow(e){
            this.showConf = false;
            console.log(e, 'kya hai ye');
            this.initData();
        }
    },
    components:{
        WidgetConfiguration,
        AddButtonGlobal,
        GlobalTable,
        GlobalTabs
    },
    mounted(){
            this.initData()
    }
}
</script>
<style lang="scss" scoped>
td,th{
    padding: 10px;
}
.widget {
    padding: 14px;

    &__header {
        display: flex;
        align-items: center;
        margin-top: 20px;
        margin-left: 25px;

        &__img {
            height: 60px;
        }

        &__title {
            margin-bottom: 0px !important;
        }

        &__desc {
            margin-top: 4px;

        }
    }
}
.selectedTab{
    border-bottom: 2px solid blue;
    font-weight: 600;
}
.tabs{
    display: flex;
    align-items: center;
}
.tab{
    margin-left: 20px;
    font-size: 0.75vw;
    cursor: pointer;
    padding: 4px;
}
</style>